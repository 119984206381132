// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               unknown
// source: node/node/params.proto

/* eslint-disable */
import {
  BinaryReader,
  BinaryWriter
} from "../../node_modules/@bufbuild/protobuf/dist/cjs/wire";

function createBaseParams() {
  return {};
}

export const Params = {
  encode(_, writer = new BinaryWriter()) {
    return writer;
  },

  decode(input, length) {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_) {
    return {};
  },

  toJSON(_) {
    const obj = {};
    return obj;
  },

  create(base) {
    return Params.fromPartial(base ?? {});
  },
  fromPartial(_) {
    const message = createBaseParams();
    return message;
  }
};
