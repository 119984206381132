import { NODE_ENDPOINT } from "../settings";

export const GET_BALANCE_NEW = "GET_BALANCE_NEW";
export const NEW_USER_ERROR = "NEW_USER_ERROR";

export function newUserError(error) {
  return {
    type: NEW_USER_ERROR,
    error,
  };
}

function getNewBalance(data) {
  return {
    type: GET_BALANCE_NEW,
    payload: data,
  };
}

export function fetchBalanceNew(address) {
  return (dispatch) => {
    // Мы в процессе загрузки данных
    // dispatch(profilePending());

    fetch(NODE_ENDPOINT + "/cosmos/bank/v1beta1/balances/" + address)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          return dispatch(newUserError(res.error));
        }

        console.log("fetchBalanceNew", res.balances);
        // Modify balances to support old ouro
        const balances = res.balances.map((item) => {
          let denom = item.denom;

          return {
            denom,
            amount: item.amount,
          };
        });

        dispatch(getNewBalance(balances));

        return res;
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(newUserError(error));
      });
  };
}
