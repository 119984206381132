import b32 from "bech32";

// Validate the address
export default function bech32Validate(address) {
  try {
    b32.decode(address);

    return true;
  } catch (error) {
    return false;
  }
}
