import React, { Component } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { Translate } from "react-localize-redux";
import {
  sendDelegateAction,
  sendDelegateMnemoAction
} from "../../api/sendDelegate";
import { connect } from "react-redux";
import "./styles/DelegateModal.scss";

Modal.setAppElement("#root");

class DelegateModal extends Component {
  constructor(params) {
    super(params);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      amount: 0,
      successMessage: false
    };
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.amount > 0 && this.state.amount <= this.props.available) {
      if (this.props.user.mnemonic) {
        this.props.dispatch(
          sendDelegateMnemoAction(
            this.props.address,
            this.state.amount,
            this.props.user.mnemonic
          )
        );
      } else {
        this.props.dispatch(
          sendDelegateAction(this.props.address, this.state.amount)
        );
      }

      this.setState({
        successMessage: true
      });
    }
  }

  onReset = e => {
    e.preventDefault();

    this.setState({
      successMessage: false
    });
  };

  render() {
    const customStyles = {
      content: {
        width: "600px",
        padding: "40px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: "hidden"
      },
      "@media (min-width: 1024px)": {
        content: {
          width: "200px"
        }
      }
    };

    if (this.props.theme === "dark") {
      customStyles.overlay = {
        backgroundColor: "#0d0d0d9c"
      };
      customStyles.content.background = "#19202a";
      customStyles.content.color = "#FFFFFF";
    }

    if (this.state.successMessage) {
      return (
        <Modal
          isOpen={this.props.showModal}
          style={customStyles}
          onRequestClose={this.props.onHide}
        >
          <div className="modal-body text-center delegate">
            <div className="py-4">
              <i className="ion ion-ios-checkmark-circle-outline display-4 text-success"></i>

              <h5 className="text-primary mt-4">
                <Translate id="transaction.yay" />
              </h5>
              <p className="text-muted">
                <Translate id="delegation.delegated_message" />
              </p>
              <div className="mt-4">
                <button
                  type="button"
                  onClick={this.onReset}
                  className="btn btn-primary"
                >
                  <Translate id="delegation.delegate_another" />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={this.props.showModal}
        style={customStyles}
        onRequestClose={this.props.onHide}
      >
        <div className="d-flex justify-content-between mb-4">
          <div></div>
        </div>
        <form
          className={`m-t-30 delegate ${
            this.props.theme === "dark" ? "delegate_dark" : ""
          }`}
          action="/"
          onSubmit={this.onSubmit}
        >
          <div className={classNames({ "text-danger": false })}>
            <label htmlFor="validator">
              <Translate id="delegation.address" />
            </label>

            <Translate>
              {({ translate }) => (
                <input
                  type="text"
                  value={this.props.address}
                  disabled={true}
                  className={false ? "form-control is-invalid" : "form-control"}
                  id="validator"
                  placeholder={translate("transaction.enter_address")}
                />
              )}
            </Translate>
          </div>

          <div className={classNames({ "text-danger": false }, "form-group")}>
            <label htmlFor="userpassword">
              <Translate id="transaction.amount" />
            </label>

            <Translate>
              {({ translate }) => (
                <input
                  type="number"
                  value={this.state.amount}
                  min={0}
                  step={0.1}
                  onChange={e => this.setState({ amount: e.target.value })}
                  className={false ? "form-control is-invalid" : "form-control"}
                  id="userpassword"
                  placeholder={translate("transaction.enter_amount")}
                />
              )}
            </Translate>
          </div>

          <div>
            <label style={{ width: "100%" }} htmlFor="">
              <Translate id="delegation.available" />
              :&nbsp;
              <b style={{ float: "right" }}>{this.props.available} OURO</b>
            </label>
          </div>

          <hr />

          <div>
            <label style={{ width: "100%" }} htmlFor="">
              <Translate id="delegation.fee" />
              :&nbsp;
              <b style={{ float: "right" }}>{0.001} OURO</b>
            </label>
          </div>

          <div className="row m-t-20">
            <div className="col-sm-12 text-right">
              <button
                className="btn btn-primary w-md waves-effect waves-light"
                type="submit"
              >
                <Translate id="delegation.delegate" />
              </button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User
});

export default connect(mapStateToProps)(DelegateModal);
