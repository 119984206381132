// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               unknown
// source: node/node/tx.proto

/* eslint-disable */
import {
  BinaryReader,
  BinaryWriter
} from "../../node_modules/@bufbuild/protobuf/dist/cjs/wire";

import { Params } from "./params";

function createBaseMsgUpdateParams() {
  return { authority: "", params: undefined };
}

export const MsgUpdateParams = {
  encode(message, writer = new BinaryWriter()) {
    if (message.authority !== "") {
      writer.uint32(10).string(message.authority);
    }
    if (message.params !== undefined) {
      Params.encode(message.params, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input, length) {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgUpdateParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.authority = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.params = Params.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object) {
    return {
      authority: isSet(object.authority)
        ? globalThis.String(object.authority)
        : "",
      params: isSet(object.params) ? Params.fromJSON(object.params) : undefined
    };
  },

  toJSON(message) {
    const obj = {};
    if (message.authority !== "") {
      obj.authority = message.authority;
    }
    if (message.params !== undefined) {
      obj.params = Params.toJSON(message.params);
    }
    return obj;
  },

  create(base) {
    return MsgUpdateParams.fromPartial(base ?? {});
  },
  fromPartial(object) {
    const message = createBaseMsgUpdateParams();
    message.authority = object.authority ?? "";
    message.params =
      object.params !== undefined && object.params !== null
        ? Params.fromPartial(object.params)
        : undefined;
    return message;
  }
};

function createBaseMsgUpdateParamsResponse() {
  return {};
}

export const MsgUpdateParamsResponse = {
  encode(_, writer = new BinaryWriter()) {
    return writer;
  },

  decode(input, length) {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgUpdateParamsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_) {
    return {};
  },

  toJSON(_) {
    const obj = {};
    return obj;
  },

  create(base) {
    return MsgUpdateParamsResponse.fromPartial(base ?? {});
  },
  fromPartial(_) {
    const message = createBaseMsgUpdateParamsResponse();
    return message;
  }
};

function createBaseMsgReinvest() {
  return { creator: "", coin: "" };
}

export const MsgReinvest = {
  encode(message, writer = new BinaryWriter()) {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.coin !== "") {
      writer.uint32(18).string(message.coin);
    }
    return writer;
  },

  decode(input, length) {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgReinvest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.coin = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object) {
    return {
      creator: isSet(object.creator) ? globalThis.String(object.creator) : "",
      coin: isSet(object.coin) ? globalThis.String(object.coin) : ""
    };
  },

  toJSON(message) {
    const obj = {};
    if (message.creator !== "") {
      obj.creator = message.creator;
    }
    if (message.coin !== "") {
      obj.coin = message.coin;
    }
    return obj;
  },

  create(base) {
    return MsgReinvest.fromPartial(base ?? {});
  },
  fromPartial(object) {
    const message = createBaseMsgReinvest();
    message.creator = object.creator ?? "";
    message.coin = object.coin ?? "";
    return message;
  }
};

function createBaseMsgReinvestResponse() {
  return {};
}

export const MsgReinvestResponse = {
  encode(_, writer = new BinaryWriter()) {
    return writer;
  },

  decode(input, length) {
    const reader =
      input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgReinvestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_) {
    return {};
  },

  toJSON(_) {
    const obj = {};
    return obj;
  },

  create(base) {
    return MsgReinvestResponse.fromPartial(base ?? {});
  },
  fromPartial(_) {
    const message = createBaseMsgReinvestResponse();
    return message;
  }
};

export const MsgServiceName = "node.node.Msg";
export class MsgClientImpl {
  constructor(rpc, opts) {
    this.service = opts?.service || MsgServiceName;
    this.rpc = rpc;
    this.UpdateParams = this.UpdateParams.bind(this);
    this.Reinvest = this.Reinvest.bind(this);
  }
  UpdateParams(request) {
    const data = MsgUpdateParams.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdateParams", data);
    return promise.then(data =>
      MsgUpdateParamsResponse.decode(new BinaryReader(data))
    );
  }

  Reinvest(request) {
    const data = MsgReinvest.encode(request).finish();
    const promise = this.rpc.request(this.service, "Reinvest", data);
    return promise.then(data =>
      MsgReinvestResponse.decode(new BinaryReader(data))
    );
  }
}

function isSet(value) {
  return value !== null && value !== undefined;
}
