import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import fetchResetPasswordToken from "../api/fetchResetPasswordToken";
import * as actionTypes from "../store/action";
import fetchResetPassword from "../api/fetchResetPassword";
import { THEME_LIGHT } from "../settings";

import ReactCountryFlag from "react-country-flag";
import ChooseLang from "../components/ChooseLang/ChooseLang";

class ResetPasswordPage extends Component {
  state = {
    password: "",
    repeatPassword: "",
    repeatPasswordError: "",
    wasSent: false,
    show: false,
  };

  componentDidMount() {
    this.props.dispatch(fetchResetPasswordToken(this.props.match.params.token)); //this.props.match.params.token

    if (this.props.loginpage === false) {
      this.props.dispatch({ type: actionTypes.LOGINPAGE, value: true });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { password, repeatPassword } = this.state;

    if (password && repeatPassword) {
      if (this.state.password !== this.state.repeatPassword) {
        this.setState({ repeatPasswordError: "Пароли не совпадают" });
      } else if (this && this.state.password) {
        this.props.dispatch(
          fetchResetPassword(this.props.match.params.token, this.state.password)
        );

        this.setState({ wasSent: true });
      }
    }
  };

  onChangeField = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  render() {
    let errorMessage;

    if (this.props.user.reset_password_error) {
      errorMessage = (
        <div className="text-danger">
          <Translate id={this.props.user.reset_password_error} />
        </div>
      );
    }

    let hasError = !!this.props.user.reset_password_error;

    const button = this.props.user.reset_password_token_padding ? (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <div className="submit">
        <button type="submit">
          <Translate id="auth.reset_password" />
        </button>
      </div>
    );

    let content = (
      <div className="formbox">
        <form action="/" id="form-registration" onSubmit={this.onSubmit}>
          <Translate>
            {({ translate }) => (
              <input
                type="password"
                size="50"
                name="password"
                value={this.state.password}
                onChange={this.onChangeField("password")}
                className={`password ${hasError ? "error" : ""}`}
                placeholder={translate("auth.enter_new_password")}
              />
            )}
          </Translate>

          {hasError && errorMessage}

          <Translate>
            {({ translate }) => (
              <input
                id="repeat-password"
                name="confirm-password"
                size="50"
                type="password"
                value={this.state.repeatPassword}
                onChange={this.onChangeField("repeatPassword")}
                className={`password ${
                  this.state.repeatPasswordError || hasError ? "error" : ""
                }`}
                placeholder={translate("auth.repeat_new_password")}
              />
            )}
          </Translate>

          {this.state.repeatPasswordError ? (
            <div className="text-danger">
              <Translate id="auth.passwords_dont_match" />
            </div>
          ) : null}

          <div className="clear"></div>

          {button}

          <div className="clear"></div>
        </form>
      </div>
    );

    if (this.props.user.reset_password_token_padding) {
      content = (
        <div className="d-flex justify-content-center m-t-20 m-b-20">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else if (this.props.user.reset_password_token_error) {
      content = (
        <div className="text-center m-t-30 m-b-30 h-50">
          <span className="text-danger font-20">
            <Translate id={this.props.user.reset_password_token_error} />
          </span>
        </div>
      );
    }

    if (
      this.state.wasSent &&
      !this.props.user.padding &&
      !hasError &&
      !this.state.repeatPasswordError
    ) {
      content = (
        <div
          className="text-center mb-3 mt-3 font-20"
          style={{ marginTop: 30 }}
        >
          <Translate id="auth.success_reset_password" />
        </div>
      );
    }

    return (
      <>
        <div id="page">
          <div id="box-4" style={{ zIndex: 7 }}>
            <div id="box-4-w">
              <ChooseLang
                languages={this.props.languages}
                setActiveLanguage={this.props.setActiveLanguage}
                activeLanguage={
                  this.props.activeLanguage && this.props.activeLanguage.code
                }
              />

              <Link to="/" className="logo">
                Ouroboros
              </Link>
            </div>
          </div>

          <div id="box-4">
            <div className="box-4-l">
              <h2>
                <Translate id="auth.reset_password" />
              </h2>
              <p>
                <Translate id="auth.forget_password" />
              </p>

              {content}

              <h6 className="mt-4">
                <Link to="/login" className={"font-500 text-primary"}>
                  <Translate id="auth.login" />
                </Link>
              </h6>

              <div className="clear"></div>
            </div>

            <div className="box-4-r">
              <img
                src={
                  this.props.theme === THEME_LIGHT
                    ? require("../assets/img/14-white.png")
                    : require("../assets/img/14.png")
                }
                alt=""
              />

              <p>
                <Translate id="auth.get_access" />
              </p>

              <div className="clear"></div>
            </div>

            <div className="clear"></div>
          </div>
        </div>
        <div
          style={{ marginTop: "126px" }}
          className="lang"
          onClick={() => {
            this.setState({ ...this.state, show: !this.state.show });

            document.getElementsByClassName("rodal")[0].style.display = "";
          }}
        >
          {this.props.languages.map((l) => {
            if (l.active) {
              return (
                <div className="lang__block">
                  <ReactCountryFlag
                    countryCode={l.code === "en" ? "gb" : l.code}
                    svg
                  />
                  <div>{l.code.toUpperCase()}</div>
                  {!this.state.show ? (
                    <i class="fas fa-caret-down" />
                  ) : (
                    <i class="fas fa-caret-up" />
                  )}
                </div>
              );
            }
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginpage: state.ui_red.loginpage,
  user: state.User,
  theme: state.Local.theme,
});

export default withRouter(
  connect(mapStateToProps)(withLocalize(ResetPasswordPage))
);
