import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { formatCoins, convertFormatCoinsToUsd } from "../../utils/coins";
import {
  ALL,
  FROM_ADDRESS,
  REINVEST,
  TO_ADDRESS
} from "../../constants/common";

class TransactionItem extends Component {
  render() {
    const { tx, account, rate, displayType } = this.props;
    const { timestamp, sender, type } = tx;

    const isFrom = sender === account.owner;
    const formatedDate = new Intl.DateTimeFormat(
      this.props.activeLanguage?.code ?? "en-US",
      {
        second: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour12: false
      }
    ).format(new Date(timestamp));

    let isReceivedTransaction = false;
    let translationString = "";
    let value = tx?.amount?.amount;
    let isVisible = true;
    switch (type) {
      case "reinvest":
        isReceivedTransaction = true;
        translationString = "transaction.reinvest";
        value = tx.posmined?.amount;
        isVisible = displayType === ALL || displayType === REINVEST;
        break;

      case "reward":
        isReceivedTransaction = true;
        translationString = "delegation.reward";
        isVisible = displayType === ALL;
        break;

      case "delegate":
        isReceivedTransaction = true;
        translationString = "delegation.delegation";
        isVisible = displayType === ALL;

        break;

      case "undelegate":
        translationString = "delegation.delegation";
        isVisible = displayType === ALL;
        isReceivedTransaction = true;

        break;
      case "transfer":
        if (isFrom) {
          translationString = "transaction.sent";
          isVisible = displayType === ALL || displayType === FROM_ADDRESS;
        } else {
          isReceivedTransaction = true;
          translationString = "transaction.received";
          isVisible = displayType === ALL || displayType === TO_ADDRESS;
        }
        break;

      default:
        if (isFrom) {
          isReceivedTransaction = false;
        } else {
          isReceivedTransaction = true;
        }
        translationString = type;

        break;
    }

    if (!isVisible) {
      return null;
    }

    return (
      <div
        className={
          !isReceivedTransaction ? "box-5 box-5-sent" : "box-5 box-5-received"
        }
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={"https://explorer.ouroboros-crypto.com/hash/" + tx?.hash}
        >
          <h3>
            <Translate
              id={translationString}
              options={{ onMissingTranslation: () => translationString }}
            />
            <span>#{tx?.height}</span>
          </h3>
        </a>

        <div className="clear"></div>

        <p className="p5-3">
          <Translate id={"transaction.amount"} />
          <br />
          <span style={{ fontSize: "inherit" }}>
            {isReceivedTransaction ? "+" : "-"}
            {formatCoins(value)} {tx?.amount?.symbol}
          </span>
          <br />${convertFormatCoinsToUsd(value, rate[tx?.amount?.symbol])}
        </p>
        <p className="p5-3">
          <Translate id={"transaction.fee"} />
          <br />
          <span style={{ fontSize: "inherit" }}>
            {formatCoins(tx?.fee.amount)} {tx?.fee?.symbol}
          </span>
          <br />${convertFormatCoinsToUsd(tx?.fee.amount, rate[tx?.fee?.symbol])}
        </p>
        <p className="p5-3">
          <Translate id={"transaction.datetime"} />
          <br />
          <span>{formatedDate}</span>
        </p>
        <div className="clear"></div>
        <p className="p5-3">
          <Translate id={"transaction.sender"} />:<br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://explorer.ouroboros-crypto.com/address/" + sender}
          >
            <span>{sender}</span>
          </a>
        </p>
        <div className="clear"></div>
        <p className="p5-3">
          <Translate id={"transaction.transaction_hash"} />:<br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://explorer.ouroboros-crypto.com/hash/" + tx?.hash}
            className="hash-link"
          >
            <span>{tx?.hash}</span>
          </a>
        </p>
        <div className="clear"></div>

      </div>
    );
  }
}

export default withLocalize(TransactionItem);
