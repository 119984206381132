import { RATE_UPDATE_SUCCESS } from "../actions/indicators";

export const INITIAL_STATE = {
  rate: 0.35,
  maintenance: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RATE_UPDATE_SUCCESS:
      return {
        ...state,
        rate: action.data.rate,
        maintenance: action.data.maintenance
      };
  }

  return state;
}
