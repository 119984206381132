import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

class Maintenance extends Component {
  render() {
    return (
      <div className="ex-pages">
        <div className="content-center">
          <div className="content-desc-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="home-wrapper text-center">
                    <img
                      src="/assets/images/logo/3.png"
                      alt="logo"
                      height="150"
                    />
                    <h3 className="m-t-30">Технические работы</h3>
                    <p className="mb-5">
                      Мы в процессе обновления некоторых крутых штук, поэтому
                      кошелек недоступен примерно до 17:30 по МСК.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withLocalize(Maintenance));
