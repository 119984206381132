import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../store/action";
import login from "../api/login";

import ChooseLang from "../components/ChooseLang/ChooseLang";
import { withLocalize, Translate } from "react-localize-redux";
import Recaptcha from "react-google-invisible-recaptcha";
import { THEME_LIGHT } from "../settings";
import Preloader from "../components/Preloader";
import "./styles/Login.scss";

class Login extends Component {
  state = {
    email: "",
    password: "",
    fa: "",
    show: false,
    search: ""
  };

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.dispatch({ type: actionTypes.LOGINPAGE, value: true });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.show !== this.state.show) {
      if (!this.state.show) {
        document.getElementsByClassName("rodal")[0].style.display = "none";
      }
    }
  }
  verifyCallback(recaptcha) {
    this.props.dispatch(
      login(this.state.email, this.state.password, this.state.fa, recaptcha)
    );
  }

  onSubmit = e => {
    e.preventDefault();

    if (this && this.state.email && this.state.password) {
      this.recaptchaInstance.execute();
    }
  };

  onChangeField = field => event => {
    this.setState({ [field]: event.target.value });
  };
  hide() {
    this.setState({ ...this.state, show: false });
  }
  render() {
    let errorMessage;

    if (this.props.user.auth_error) {
      errorMessage = (
        <div className="text-danger" style={{ marginTop: 20 }}>
          <Translate id={this.props.user.auth_error} />
        </div>
      );
    }

    if (this.props.user.address) {
      this.props.dispatch({ type: actionTypes.LOGINPAGE, value: false });

      this.props.history.push("/");

      return <div>Redirecting...</div>;
    }

    const button = (
      <div className="submit">
        <button type="submit" disabled={this.props.user.pending}>
          <Translate id="auth.login" />
          {this.props.user.pending && <Preloader />}
        </button>
      </div>
    );

    const hasError = !!this.props.user.auth_error;

    // Двухфакторка
    if (this.props.user.fa_required) {
      return (
        <>
          <Recaptcha
            ref={e => (this.recaptchaInstance = e)}
            sitekey="6Lel4sAUAAAAADDOXrPbbOT6AQCYvcOzCPGIR7SQ"
            onResolved={e => this.verifyCallback(e)}
          />

          <div id="page">
            <div id="box-4" style={{ zIndex: 7 }}>
              <div id="box-4-w">
                <ChooseLang
                  languages={this.props.languages}
                  setActiveLanguage={this.props.setActiveLanguage}
                  activeLanguage={
                    this.props.activeLanguage && this.props.activeLanguage.code
                  }
                />

                <Link to="/" className="logo">
                  Ouroboros
                </Link>
              </div>
            </div>
            <div className="clear"></div>

            <div id="box-4">
              <div className="box-4-l">
                <h2>
                  <Translate id="fa.title" />
                </h2>
                <p>
                  <Translate id="auth.trying_to_login" /> {this.state.email}
                </p>

                <div className="formbox mt-2">
                  <form
                    action="/"
                    id="form-registration"
                    onSubmit={this.onSubmit}
                  >
                    <Translate>
                      {({ translate }) => (
                        <input
                          id="fa-code"
                          type="text"
                          className={`password ${hasError ? "error" : ""}`}
                          value={this.state.fa}
                          onChange={this.onChangeField("fa")}
                          placeholder="2FA"
                        />
                      )}
                    </Translate>

                    {hasError && errorMessage}

                    {button}
                  </form>
                </div>
              </div>

              <div className="box-4-r">
                <img src={require("../assets/img/14.png")} alt="" />

                <p>
                  <Translate id="auth.get_access" />
                </p>
              </div>
            </div>
            <div
              style={{ marginTop: "126px" }}
              className="lang"
              onClick={() => {
                this.setState({ ...this.state, show: !this.state.show });

                document.getElementsByClassName("rodal")[0].style.display = "";
              }}
            ></div>
          </div>
        </>
      );
    }

    return (
      <>
        <Recaptcha
          ref={e => (this.recaptchaInstance = e)}
          sitekey="6Lel4sAUAAAAADDOXrPbbOT6AQCYvcOzCPGIR7SQ"
          onResolved={e => this.verifyCallback(e)}
        />

        <div id="page">
          <div id="box-4" style={{ zIndex: 7 }}>
            <div id="box-4-w">
              <ChooseLang
                languages={this.props.languages}
                setActiveLanguage={this.props.setActiveLanguage}
                activeLanguage={
                  this.props.activeLanguage && this.props.activeLanguage.code
                }
              />

              <Link to="/" className="logo">
                Ouroboros
              </Link>
            </div>
          </div>
          <div id="box-4">
            <div className="box-4-l ">
              <h2>
                <Translate id="auth.welcome" />
              </h2>
              <p>
                <Translate id="auth.get_in" />
              </p>

              <div id="box-4-1-1">
                <Link to="/login">
                  <Translate id="auth.email_login" />
                </Link>
                <Link to="/mnemonic">
                  <Translate id="auth.memo_login" />
                </Link>
              </div>

              <div className="formbox mt-1">
                <form
                  action="/"
                  id="form-registration"
                  onSubmit={this.onSubmit}
                >
                  <Translate>
                    {({ translate }) => (
                      <input
                        id="email"
                        type="email"
                        size="50"
                        name="email"
                        className={`email ${hasError ? "error" : ""}`}
                        value={this.state.email}
                        onChange={this.onChangeField("email")}
                        placeholder={translate("auth.enter_email")}
                      />
                    )}
                  </Translate>

                  <Translate>
                    {({ translate }) => (
                      <input
                        id="userpassword"
                        type="password"
                        size="50"
                        name="password"
                        className={`password ${hasError ? "error" : ""}`}
                        value={this.state.password}
                        onChange={this.onChangeField("password")}
                        placeholder={translate("auth.enter_password")}
                      />
                    )}
                  </Translate>

                  {hasError && errorMessage}

                  {button}
                </form>
              </div>

              <div>
                <p>
                  {/* <Translate id="auth.doesnt_have" /> &nbsp; */}
                  <Link to="register" className="register-button">
                    <Translate id="auth.register" />
                  </Link>
                  &nbsp;
                </p>
              </div>

              <div className="mt-4" style={{ marginTop: 15, marginBottom: 15 }}>
                <Link className="text-primary" to="/reset-password">
                  <i className="mdi mdi-lock" />{" "}
                  <Translate id="auth.forget_password" />
                </Link>
              </div>
            </div>

            <div className="box-4-r">
              <img
                src={
                  this.props.theme === THEME_LIGHT
                    ? require("../assets/img/14-white.png")
                    : require("../assets/img/14.png")
                }
                alt=""
              />

              <p>
                <Translate id="auth.get_access" />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loginpage: state.ui_red.loginpage,
  user: state.User,
  theme: state.Local.theme,
  lang: state.Local.lang
});

export default connect(mapStateToProps)(withLocalize(Login));
