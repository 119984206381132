export const RATE_UPDATE_SUCCESS = 'RATE_UPDATE_SUCCESS';

export const START_POLLING_INDICATORS = "START_POLLING_INDICATORS";
export const STOP_POLLING_INDICATORS = "STOP_POLLING_INDICATORS";



export function startPollingIndicators() {
    return {
        type: START_POLLING_INDICATORS
    }
}

export function rateUpdateSuccess(data) {

    return {
        type: RATE_UPDATE_SUCCESS,
        data: data
    }
}