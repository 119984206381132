export const VALIDATORS_UPDATE_PENDING = "VALIDATORS_UPDATE_PENDING";
export const VALIDATORS_UPDATE_SUCCESS = "VALIDATORS_UPDATE_SUCCESS";

export const DELEGATIONS_UPDATE_PENDING = "DELEGATIONS_UPDATE_PENDING";
export const DELEGATIONS_UPDATE_SUCCESS = "DELEGATIONS_UPDATE_SUCCESS";

export const DELEGATE_SUCCESS = "DELEGATE_SUCCESS";
export const UNDELEGATE_SUCCESS = "UNDELEGATE_SUCCESS";

export const RESET_REWARD = "RESET_REWARD";

export const REWARDS_FETCH_SUCCESS = "REWARDS_FETCH_SUCCESS";

export function validatorsUpdatePending() {
  return {
    type: VALIDATORS_UPDATE_PENDING,
  };
}

export function validatorsUpdateSuccess(data, total) {
  return {
    type: VALIDATORS_UPDATE_SUCCESS,
    data: data,
    total: total,
  };
}

export function delegateSuccess(data) {
  return {
    type: DELEGATE_SUCCESS,
    data: data,
  };
}

export function undelegateSuccess(data) {
  return {
    type: UNDELEGATE_SUCCESS,
    data: data,
  };
}

export function rewardsFetchSuccess(data, total) {
  return {
    type: REWARDS_FETCH_SUCCESS,
    data: data,
    total: total,
  };
}

export function resetReward() {
  return {
    type: RESET_REWARD,
  };
}
