import React, { useState, useEffect } from "react";

const CountUp = ({ start = 0, end, duration = 2000, interval = 100 }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    if (start === end) return;

    const step = (end - start) / (duration / interval);
    let current = start;

    const timer = setInterval(() => {
      current += step;
      if ((start < end && current >= end) || (start > end && current <= end)) {
        setCount(end);
        clearInterval(timer);
      } else {
        setCount(current.toFixed(6));
      }
    }, interval);

    return () => clearInterval(timer);
  }, [start, end, duration, interval]);

  return <span>{count}</span>;
};

export default CountUp;
