import { txFetchSuccess, txFetchError, txFetchPending } from "../actions/tx";
import { NODE_ENDPOINT_NEW } from "../settings";

export function fetchTxsActionNew(type, address, txs, page = 1) {
  return (dispatch) => {
    dispatch(txFetchPending());
    fetch(
      NODE_ENDPOINT_NEW +
        "/txs/" +
        address +
        "?limit=20&type=" +
        type +
        "&page=" +
        page,
      {
        method: "GET",
        // headers: {
        //   // Authorization: "JWT " + token,
        // },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (page > 1) {
          data.results = txs.concat(data.results);
        }
        dispatch(txFetchSuccess(data));
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(txFetchError(error));
      });
  };
}
