import React, {PureComponent} from 'react';
import PropTypes from 'prop-types'
import {compose} from 'redux';
import classNames from 'classnames';
import {connect} from 'react-redux';
import updateAccountAction from "../api/updateAccount";
import LightLoader from "../components/Loader/LightLoader";
import {Link} from "react-router-dom";
import {Translate, withLocalize} from "react-localize-redux";
import {ActionButton} from '../components/Buttons';
import PinCodeInput from '../components/Inputs/PinCodeInput';
import withDefaultAccountType from '../hoc/withDefaultAccountType';
import Header from "../components/Header/Header";
import './styles/Settings.scss';
import {THEME_DARK} from "../settings";
import {reverseTheme} from "../actions/local";
import Preloader from "../components/Preloader";

class Settings extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: props.user.email || '',
            telegram: props.user.telegram || '',
            reinvest: this.props.user.reinvest || 0,
            newPassword: '',
            repeatNewPassword: '',
            saved: false,
            pinCode: '',
            isValidPinCode: true,
        };

        this.switchTheme = this.switchTheme.bind(this);
    }

    switchTheme() {
        this.props.dispatch(reverseTheme());
    }

    componentDidUpdate(prevProps) {
        const {user: {profile_update_error}} = this.props;

        if (profile_update_error && profile_update_error !== prevProps.user.profile_update_error) {
            this.setState({saved: false, isValidPinCode: !profile_update_error.match('pin')});
        }
    }

    updateReinvest = (e) => {
        let newValue = e.target.value.replace(/,/g, '.');

        if (newValue.startsWith('0') && !newValue.includes(',') && !newValue.includes('.') && newValue.length > 1) {
            newValue = newValue.substr(1);
        }

        if (newValue === '') {
            newValue = '0';
        }

        let value = parseFloat(newValue);

        if (value !== 0 && value < 0.01) {
            value = 0.01;
            newValue = "0.01";
        }

        if (value === 0 || value >= 0.01) {
            this.setState({reinvest: newValue});
        }
    };

    onSubmit = () => {
        const {telegram, reinvest, newPassword, email, pinCode: pin} = this.state;

        if (!pin || (pin.length === 4 && pin.match('[0-9]'))) {
            const {dispatch} = this.props;
            dispatch(updateAccountAction({
                email,
                telegram,
                reinvest,
                newPassword,
                pin,
                language: this.props.activeLanguage.code
            }));

            this.setState({pinCode: '', saved: true});
        } else {
            this.setState({isValidPinCode: false})
        }
    };

    onChangeField = field => (event) => {
        if (field === 'pinCode') {
            this.setState({[field]: event, isValidPinCode: true});
        } else {
            this.setState({[field]: event.target.value});
        }
    };

    render() {
        const {
            user: {
                update_pending,
                fa_enabled,
                pin_set,
                profile_update_error,
                address
            }
        } = this.props;
        const {isValidPinCode, saved} = this.state;

        const button = (
          <div className={"submit"} style={{width: 250}}>
              <ActionButton
                type="button"
                onClick={this.onSubmit}
              >
                  <>
                      <Translate id="settings.save"/>
                      {update_pending && (
                        <Preloader />
                      )}
                  </>
              </ActionButton>
          </div>
        );

        const savedMessage = !profile_update_error ? (
            <p className="text-success m-b-30 ">
                <Translate id="settings.saved"/>
            </p>
        ) : (
            <p className="text-danger m-b-30 ">
                <Translate id={profile_update_error}/>
            </p>
        );

        const faClass = !fa_enabled ? "btn-success" : "btn-warning";
        const themeClass = this.props.theme == THEME_DARK ? "btn-warning" : "btn-success";

        const pinCodeClass = !pin_set ? "add" : "remove";

        return (
            <>
                <div id="box-5-w">
                    <div id="box-5">
                        <div id="top-w">
                            <Header address={address}/>
                        </div>
                        <div className="box-5-w">

                            <h2><Translate id="settings.title"/></h2>


                            <div className="clear"></div>

                            {saved && !update_pending ? savedMessage : (
                                <p className="text-muted m-b-30 ">
                                    <Translate id="settings.how_to_save"/>
                                </p>
                            )}

                            <div className="settings">
                                <div>
                                    <div className="col-12">
                                        <div className="card-body">

                                            <div className="settings__item" style={{paddingTop: 30}}>
                                                <label
                                                    htmlFor="email"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Email
                                                </label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeField('email')}
                                                        id="email"
                                                        size="50"
                                                        name="address"
                                                        placeholder="Email"
                                                    />
                                                </div>
                                            </div>

                                            <div className="settings__item">
                                                <label
                                                    htmlFor="telegram"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Telegram
                                                </label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        value={this.state.telegram}
                                                        onChange={this.onChangeField('telegram')}
                                                        id="telegram"
                                                        size="50"
                                                        name="address"
                                                        placeholder="Telegram"
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="settings__item">
                                                <label
                                                    htmlFor="reinvest"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    <Translate id="paramining.reinvest"/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        value={this.state.reinvest}
                                                        onChange={this.updateReinvest}
                                                        id="reinvest"
                                                        size="50"
                                                        name="reinvest"
                                                        placeholder="0.02"
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="settings__item">
                                                <label
                                                    htmlFor="password"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    <Translate id="settings.new_password"/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="password"
                                                        value={this.state.newPassword}
                                                        onChange={this.onChangeField('newPassword')}
                                                        id="password"
                                                        size="50"
                                                        name="newPassword"
                                                    />
                                                </div>
                                            </div>

                                            <div className="settings__item">
                                                <label
                                                    htmlFor="password"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    <Translate id="fa.title"/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <Link to={"/2fa"}>
                                                        <button type="button"
                                                                className={`waves-effect waves-light btn ${faClass} btn-lg`}>
                                                            {this.props.user.fa_enabled ? (
                                                                <Translate id="settings.disable"/>
                                                            ) : (
                                                                <Translate id="settings.enable"/>
                                                            )}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="settings__item">
                                                <label
                                                    htmlFor="password"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    <Translate id="settings.dark_theme"/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <button type="button" onClick={this.switchTheme}
                                                            className={`waves-effect waves-light btn ${themeClass} btn-lg`}>
                                                        {this.props.theme === THEME_DARK ? (
                                                            <Translate id="settings.disable"/>
                                                        ) : (
                                                            <Translate id="settings.enable"/>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="settings__item pin-code">
                                                <label
                                                    htmlFor="pin-code"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    <Translate id={`pin_code.${pinCodeClass}_pin_code`}/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <PinCodeInput
                                                        onChange={this.onChangeField('pinCode')}
                                                        id="pin-code"
                                                        isValid={isValidPinCode}
                                                        isClear={saved}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-0 form-group btn-save" style={{paddingBottom: 30}}>
                                                <div>
                                                    {button}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </>
        )

        /*return (
            <>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">

                            <div className="col-sm-6">
                                <h4 className="page-title"><Translate id="settings.title"/></h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    <h4 className="mt-0 header-title"><Translate id="settings.title"/></h4>
                                    {saved && !update_pending ? savedMessage : (
                                        <p className="text-muted m-b-30 ">
                                            <Translate id="settings.how_to_save"/>
                                        </p>
                                    )}

                                    <div className="form-group row">
                                        <label
                                            htmlFor="email"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Email
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                className="form-control"
                                                type="email"
                                                value={this.state.email}
                                                disabled
                                                id="email"/>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="telegram"
                                            className="col-sm-2 col-form-label"
                                        >
                                            Telegram
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={this.state.telegram}
                                                onChange={this.onChangeField('telegram')}
                                                id="telegram"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="reinvest"
                                            className="col-sm-2 col-form-label"
                                        >
                                            <Translate id="paramining.reinvest"/>
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={this.state.reinvest}
                                                onChange={this.updateReinvest}
                                                id="reinvest"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-2 col-form-label"
                                        >
                                            <Translate id="settings.new_password"/>
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={this.state.newPassword}
                                                onChange={this.onChangeField('newPassword')}
                                                id="password"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="password"
                                            className="col-sm-2 col-form-label"
                                        >
                                            <Translate id="fa.title"/>
                                        </label>
                                        <div className="col-sm-10">
                                            <Link to={"/2fa"}>
                                                <button type="button" className={`waves-effect waves-light btn ${faClass} btn-lg`}>
                                                    {this.props.user.fa_enabled ? (
                                                        <Translate id="settings.disable"/>
                                                    ) : (
                                                        <Translate id="settings.enable"/>
                                                    )}
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div
                                        className={classNames(
                                            "form-group row d-flex align-items-center",
                                            { "text-danger": pin_set }
                                        )}
                                    >
                                        <label
                                            htmlFor="pin-code"
                                            className="col-sm-2 col-form-label"
                                        >
                                            <Translate id={`pin_code.${pinCodeClass}_pin_code`}/>
                                        </label>
                                        <div className="col-sm-8 d-flex align-items-center">
                                            <PinCodeInput
                                                onChange={this.onChangeField('pinCode')}
                                                id="pin-code"
                                                isValid={isValidPinCode}
                                                isClear={saved}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-0 form-group">
                                        <div>
                                            {button}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )*/
    }
}

Settings.propTypes = {
    user: PropTypes.shape({
        update_pending: PropTypes.bool,
        fa_enabled: PropTypes.bool,
        pin_set: PropTypes.bool,
    }),
};

Settings.defaultProps = {
    user: {
        update_pending: false,
        fa_enabled: false,
        pin_set: false,
    }
};

const mapStateToProps = state => ({
    tx: state.Tx,
    user: state.User,
    theme: state.Local.theme,
});

const enhance = compose(
    withDefaultAccountType,
    connect(mapStateToProps),
    withLocalize,
);

export default enhance(Settings);
