import React, { Component } from "react";
import Layout from "./components/Layout/Layout";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Transactions from "./views/Transactions";
import Login from "./views/Login";
import Register from "./views/Register";
import GetResetPasswordLinkPage from "./views/GetResetPasswordLinkPage";
import ResetPasswordPage from "./views/ResetPasswordPage";
import { withLocalize } from "react-localize-redux";
import loadProfile from "./api/fetchProfile";
import { compose } from "redux";

import fetchAccountAction from "./api/fetchAccount";

import Paramining from "./views/Paramining";
import PrivateRoute from "./components/Routes/PrivateRoute";
import Logout from "./views/Logout";
import MnemoPage from "./views/MnemoPage";
import Settings from "./views/Settings";
import FAPage from "./views/FAPage";
import { startPollingProfile, stopPollingProfile } from "./actions/user";
import { renderToStaticMarkup } from "react-dom/server";
import { startPollingIndicators } from "./actions/indicators";
import { startPollingNewRate } from "./actions/newRate";
import Maintenance from "./views/Maintenance";
import Wallets from "./views/Wallets";
import Validator from "./views/Validator";

import walletTranslation from "./translations/wallet";
import transactionTranslation from "./translations/transaction";
import paraminingTranslation from "./translations/paramining";
import menuTranslation from "./translations/menu";
import faTranslation from "./translations/fa";
import settingsTranslaction from "./translations/settings";
import pinCode from "./translations/pin-code";
import authTranslaction from "./translations/auth";
import errorTranslation from "./translations/error";
import mnemoTranslation from "./translations/mnemo";
import commonTranslation from "./translations/common";
import walletsTranslation from "./translations/wallets";
import competitionTranslation from "./translations/competition";

import { changeLanguage } from "./actions/local";

import "./assets/sass/styles.sass";
import "./App.css";

import Validators from "./views/Validators";
import ChangeEmailPage from "./views/ChangeEmailPage";

class App extends Component {
  state = { address: "" };

  constructor(props) {
    super(props);
    this.props.initialize({
      languages: [
        { name: "Russian", code: "ru" },
        { name: "English", code: "en" },
        { name: "Turkish", code: "tr" },
        { name: "Chinese", code: "cn" },
        { name: "Korean", code: "kr" },
        { name: "Thai", code: "th" },
        { name: "Japanese", code: "jp" },
        { name: "Vietnamese", code: "vn" },
        { name: "	Uzbek", code: "uz" },
        { name: "Hindi", code: "IN" }
      ],
      options: { renderToStaticMarkup }
    });

    this.props.addTranslation(walletTranslation);
    this.props.addTranslation(transactionTranslation);
    this.props.addTranslation(paraminingTranslation);
    this.props.addTranslation(menuTranslation);
    this.props.addTranslation(faTranslation);
    this.props.addTranslation(settingsTranslaction);
    this.props.addTranslation(authTranslaction);
    this.props.addTranslation(errorTranslation);
    this.props.addTranslation(pinCode);
    this.props.addTranslation(mnemoTranslation);
    this.props.addTranslation(commonTranslation);
    this.props.addTranslation(walletsTranslation);
    this.props.addTranslation(competitionTranslation);

    if (this.props.local.lang) {
      this.props.setActiveLanguage(this.props.local.lang);
    } else {
      const navLang = navigator.language;
      console.log(navLang, "NAV");
      if (navLang && (navLang === "ru" || navLang.includes("ru-"))) {
        this.props.setActiveLanguage("ru");
        this.props.dispatch(changeLanguage("ru"));
      } else if (navLang && (navLang === "tr" || navLang.includes("tr-"))) {
        this.props.setActiveLanguage("tr");
        this.props.dispatch(changeLanguage("tr"));
      } else {
        this.props.setActiveLanguage("en");
        this.props.dispatch(changeLanguage("en"));
      }
    }

    // @todo remove
    //this.props.setActiveLanguage("ru");
  }

  componentDidMount() {
    if (localStorage.getItem("lang")) {
      this.props.setActiveLanguage(localStorage.getItem("lang"));
    }
    this.setState({ address: this.props.user.address });

    this.props.dispatch(startPollingIndicators());
    this.props.dispatch(startPollingNewRate());

    // Загружаем профиль
    if (this.props.user.address) {
      this.props.dispatch(fetchAccountAction());
      this.props.dispatch(loadProfile(this.props.user.address));

      this.props.dispatch(startPollingProfile(this.props.user.address));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (
      prevState.address !== this.props.user.address &&
      this.props.user.address !== ""
    ) {
      this.props.dispatch(stopPollingProfile()); // На случай, если идет переключение между аккаунтами
      this.props.dispatch(startPollingProfile(this.props.user.address));
      this.setState({ address: this.props.user.address });
    }
  }

  render() {
    if (this.props.indicators.maintenance) {
      return <Maintenance />;
    }

    const theme = this.props.local.theme;

    return (
      <Layout
        topbar={this.props.topbar}
        footer={this.props.footer}
        sidebar={this.props.sidebar}
        theme={theme}
        loginpage={this.props.loginpage}
      >
        <Switch>
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/settings" exact component={Settings} />
          <PrivateRoute path="/transactions" exact component={Transactions} />
          <PrivateRoute path="/posmining" exact component={Paramining} />
          <PrivateRoute path="/2fa" exact component={FAPage} />
          <PrivateRoute path="/wallets" exact component={Wallets} />

          <Route path="/login" exact component={Login} />
          <Route path="/mnemonic" exact component={MnemoPage} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/register" exact component={Register} />
          <Route
            path="/reset-password"
            exact
            component={GetResetPasswordLinkPage}
          />
          <Route
            path="/change-email/:token"
            exact
            component={ChangeEmailPage}
          />
          <Route
            path="/reset-password/:token"
            exact
            component={ResetPasswordPage}
          />
          <Route path="/validators" exact component={Validators} />
          <Route
            path="/validators/:validatorAddress"
            exact
            component={Validator}
          />
          <Route
            exact
            path="/paramining"
            render={() => <Redirect to="/posmining" />}
          />
        </Switch>
      </Layout>
    );
  }
}

const mapStatetoProps = state => {
  return {
    topbar: state.ui_red.topbar,
    footer: state.ui_red.footer,
    sidebar: state.ui_red.sidebar,
    loginpage: state.ui_red.loginpage,
    user: state.User,
    account: state.Profile,
    indicators: state.Indicators,
    local: state.Local
  };
};

const enhance = compose(withRouter, connect(mapStatetoProps), withLocalize);

export default enhance(App);
