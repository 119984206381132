import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "./SideBar";

class layout extends Component {
  render() {
    const { children, theme } = this.props;

    return (
      <>
        <div id="page" data-theme={theme}>
          {!this.props.loginpage ? <Sidebar /> : null}
          {children}
        </div>
        <div className="noone">
          <img src="images/5-2.png" alt="" />
        </div>

        <script src="js/main.js"></script>
      </>
    );
  }
}

export default withRouter(layout);
