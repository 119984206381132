import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";

class Header extends Component {
  render() {
    const { address } = this.props;
    return (
      <div id="top">
        <h2>
          <Translate id={"common.welcome_to"} />
        </h2>
        <p>
          <Translate id={"common.your_wallet"} />: {address}
        </p>
        <Link className="log-out" to="/logout">
          <Translate id="menu.logout" />
        </Link>
        <div className="clear" />
      </div>
    );
  }
}

export default withLocalize(Header);
