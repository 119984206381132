import React, {Component} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import QRious from 'qrious';
import LightLoader from "../components/Loader/LightLoader";
import enableFA from "../api/enableFA";
import disableFA from "../api/disableFA";
import { Translate, withLocalize } from "react-localize-redux";
import withDefaultAccountType from '../hoc/withDefaultAccountType';
import Header from "../components/Header/Header";

class FAPage extends Component {
    state = {
        fa: "",
        show: true
    };

    componentDidMount() {
        this.setState({show: !!this.props.show});
    }

    onSubmit = e => {
        e.preventDefault();

        if (this.state.fa) {
            this.props.dispatch(enableFA(this.state.fa));

            this.setState({fa: ''});
        }
    };

    onDisable = e => {
        e.preventDefault();

        if (this.state.fa) {
            this.props.dispatch(disableFA(this.state.fa));

            this.setState({fa: ''});
        }
    }

    render() {
        const { account } = this.props;

        const qr = new QRious({
            value: this.props.account.fa_qr,
            size: 500
        });

        const originalButton = this.props.account.fa_enabled ? (
            <button type="submit">
                <Translate id="settings.disable" />
            </button>
        ) : (
            <button type="submit">
                <Translate id="settings.enable" />
            </button>
        );

        const button = this.props.account.update_pending ? (<LightLoader/>) : originalButton;
        const hasError = this.props.account.fa_error !== "";
        const errorMessage = hasError ? (
            <div className="text-danger" style={{ marginTop: '20px' }}>
                <Translate id={this.props.account.fa_error} />
            </div>
        ) : null;

        let body = (
            <div id="box-2-w">
                <div id="box-2">
                    <div id="top-w">
                        <Header address={account.address}/>
                    </div>

                    <div className="box-2-t">
                        <h2><Translate id="fa.title" /></h2>
                    
                        <p className="p2-1"><Translate id="fa.enable_1_text" /></p>
                        <p className="p2-1"><Translate id="fa.enable_2_text" /></p>
                    
                        <h3><Translate id="fa.how_to" />:</h3>                    

                        <p className="p2-2 p2-2-1">
                            <span className="sp2-1">01</span>
                            <Translate id="fa.how_to_1" /> 
                            <br />
                            <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank" className="appstore"></a>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru" target="_blank" className="googleplay"></a>
                        </p>
                        
                        <p className="p2-2 p2-2-2">
                            <span className="sp2-1">02</span>
                            <Translate id="fa.how_to_2" />: <span className="sp2-2">{this.props.account.fa_secret}</span>
                            <br />
                            <span className="sp2-3"><Translate id="fa.important" /> <Translate id="fa.how_to_2_2" /></span>
                        </p>
                    
                        <div className="clear"></div>
                    
                        <p className="p2-2 p2-2-3">
                            <span className="sp2-1">03</span>
                            <Translate id="fa.how_to_3" /><br/><Translate id="fa.how_to_3_2" />: <span className="sp2-2">{this.props.account.fa_secret}</span>
                        </p>
                        
                        <p className="p2-2 p2-2-4">
                            <span className="sp2-1">04</span>
                            <Translate id="fa.how_to_4" /> 
                            <br />
                            <span className="sp2-3"><Translate id="fa.how_to_4_2" /></span>
                        </p>
                    
                        <div className="clear"></div>
                    </div>
                    
                    <div className="box-2-b">
                        <h2><Translate id={"fa.enter_code"} />:</h2>
                    
                        <div className="formbox">
                            <form name="form" id="form-code" onSubmit={this.onSubmit}>
                                <Translate>
                                    {({ translate }) =>
                                        <input 
                                            type="text" 
                                            name="code"
                                            value={this.state.fa}
                                            onChange={e => this.setState({fa: e.target.value})}
                                            className={hasError ? "error" : ""} 
                                            id="fa"
                                            placeholder={translate("fa.enter_code")}
                                        />
                                    }
                                </Translate>
                              
                                <div className="submit">
                                    {button}
                                </div>

                                <div className="clear"></div>

                                {errorMessage}

                            </form>
                        </div>


                        <p className="p2-3" style={{width: 150, background: `url(${qr.toDataURL()}) no-repeat 80px 40px`, backgroundSize: '100px'}}><Translate id={"fa.launch_the_app"} /></p>
                        
                        <div className="clear"></div>
                    </div>

                    <div className="clear"></div>
                </div>
            </div>
        );

        if (this.props.account.fa_enabled) {
            body = (
                <div id="box-2-w">
                    <div id="box-2">
                        <div id="top-w">
                            <Header address={account.address}/>
                        </div>

                        <div className="box-2-t">
                            <h2><Translate id="fa.title" /></h2>
                        
                            <h4 className="mt-0 header-title"><Translate id="fa.enabled" /></h4>

                            <h3><Translate id="fa.how_to_disable" />:</h3>

                            <h4 className="mt-0 header-title"><Translate id="fa.how_to_disable_1" /></h4>

                            <p className="m-b-20"><b><Translate id="fa.warning" /></b> <Translate id="fa.why_not_disable_1" /></p>
                        
                            <div className="clear"></div>
                        </div>
                        
                        <div className="box-2-b">
                            <h2><Translate id={"fa.enter_code"} />:</h2>
                        
                            <div className="formbox">
                                <form name="form" id="form-code" onSubmit={this.onDisable}>
                                    <Translate>
                                        {({ translate }) =>
                                            <input 
                                                type="text" 
                                                name="code"
                                                value={this.state.fa}
                                                onChange={e => this.setState({fa: e.target.value})}
                                                className={hasError ? "error" : ""} 
                                                id="fa"
                                                placeholder={translate("fa.enter_code")}
                                            />
                                        }
                                    </Translate>

                                    {errorMessage}
                                  
                                    <div className="submit">
                                        {button}
                                    </div>
                                  
                                    <div className="clear"></div>
                                </form>
                            </div>
                            
                            <div className="clear"></div>
                        </div>

                        <div className="clear"></div>
                    </div>
                </div>
            );
        }

        return body;
    }
}

const mapStateToProps = state => ({
    tx: state.Tx,
    account: state.User
});

const enhance = compose(
  withDefaultAccountType,
  connect(mapStateToProps),
  withLocalize
);

export default enhance(FAPage);
