import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { formatCoins, convertFormatCoinsToUsd } from "../../utils/coins";

class MainPageTransactionItem extends Component {
  state = { tx: undefined };

  componentDidMount() {
    this.setState({ tx: this.props.tx });
  }

  show = () => {
    this.props.onTxShow(this.state.tx);
  };

  render() {
    const { tx, account, rate } = this.props;
    const { timestamp, sender, type } = tx;
    const isFrom = sender === account.owner;

    const formatedDate = new Intl.DateTimeFormat(
      this.props.activeLanguage?.code ?? "en-US",
      {
        second: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour12: false
      }
    ).format(new Date(timestamp));

    let isReceivedTransaction = false;
    let translationString = "";
    let value = tx?.amount?.amount;
    switch (type) {
      case "reinvest":
        isReceivedTransaction = true;
        translationString = "transaction.reinvest";
        value = tx.posmined?.amount;
        break;
      case "reward":
        isReceivedTransaction = true;
        translationString = "delegation.reward";

        break;
      case "delegate":
        isReceivedTransaction = true;
        translationString = "delegation.delegation";

        break;
      case "undelegate":
        translationString = "delegation.delegation";

        break;
      case "transfer":
        if (isFrom) {
          translationString = "transaction.sent";
        } else {
          isReceivedTransaction = true;
          translationString = "transaction.reveived";
        }
        break;

      default:
        if (isFrom) {
        } else {
          isReceivedTransaction = true;
        }
        translationString = type;

        break;
    }

    return (
      <div
        className={`transaction ${
          isReceivedTransaction ? "transaction-received" : "transaction-sent"
        }`}
      >
        <h3>
          <span>
            <Translate id={translationString} />
          </span>
          <br />
          {formatedDate}
        </h3>
        <p>
          <span>
            {isReceivedTransaction ? "+" : "-"}
            {parseFloat(formatCoins(value)).toFixed(2)} {tx?.amount?.symbol}
          </span>
          <br />
          {parseFloat(
            convertFormatCoinsToUsd(value, rate[tx?.amount?.symbol])
          ).toFixed(2)}
          $
        </p>
        <div className="clear" />
      </div>
    );
  }
}

export default withLocalize(MainPageTransactionItem);
