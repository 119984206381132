import {
  txSendReinvestPending,
  txSendReinvestSuccess
} from "../actions/reinvest";

const { DirectSecp256k1HdWallet } = require("@cosmjs/proto-signing");
const { SigningStargateClient } = require("@cosmjs/stargate");

const settings = require("../settings");

const RPC_ENDPOINT = settings.RPC_ENDPOINT;

// We need to register the custom message type
const { Registry } = require("@cosmjs/proto-signing");
const { MsgReinvest } = require("../proto/tx");

const registry = new Registry();

registry.register("/node.node.MsgReinvest", MsgReinvest);

async function handleTx(user) {
  const wallet = await DirectSecp256k1HdWallet.fromMnemonic(user.mnemonic, {
    prefix: "ouro"
  });
  const [account] = await wallet.getAccounts();

  const client = await SigningStargateClient.connectWithSigner(
    RPC_ENDPOINT,
    wallet,
    {
      registry
    }
  );

  const gasLimit = 200000;
  const feeAmount = {
    amount: [
      {
        denom: "ouro",
        amount: "2000" // Fee in smallest denomination
      }
    ],
    gas: gasLimit.toString()
  };

  const msgAny = {
    typeUrl: "/node.node.MsgReinvest",
    value: {
      creator: account.address,
      coin: "ouro"
    }
  };

  // Step 4: Sign and broadcast the transaction
  const tx = await client.signAndBroadcast(
    account.address,
    [msgAny],
    feeAmount,
    "Reinvest"
  );

  console.log("Transaction successful:", tx.transactionHash);

  return tx;
}

export default function sendReinvest(user) {
  return (dispatch, getState) => {
    dispatch(txSendReinvestPending());

    return handleTx(user).then(tx => {
      dispatch(txSendReinvestSuccess());
    });
  };
}
