import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { SigningStargateClient, coin } from "@cosmjs/stargate";

import { API_ENDPOINT, RPC_ENDPOINT } from "../settings";
import { logout, stopPollingProfile } from "../actions/user";
import { delegateSuccess } from "../actions/validators";

export function sendDelegateMnemoAction(validator, amount, mnemonic) {
  return dispatch => {
    if (!mnemonic) {
      return;
    }
    DirectSecp256k1HdWallet.fromMnemonic(mnemonic, {
      prefix: "ouro"
    }).then(wallet => {
      wallet.getAccounts().then(accounts => {
        SigningStargateClient.connectWithSigner(RPC_ENDPOINT, wallet).then(
          client => {
            const amountReal = (parseFloat(amount) * 1e6).toString();
            const fee = {
              amount: [
                {
                  denom: "ouro",
                  amount: "2000"
                }
              ],
              gas: "95000000000"
            };

            client
              .delegateTokens(
                accounts[0].address,
                validator,
                coin(amountReal, "ouro"),
                fee,
                "Delegation"
              )
              .then(result => {
                console.log("MEMO_DELEGATION", result);
                return dispatch(delegateSuccess(result.txhash));
              });
          }
        );
      });
    });
  };
}

export function sendDelegateAction(validator, amount) {
  return dispatch => {
    const token = localStorage.token;

    if (!token) {
      return;
    }

    fetch(API_ENDPOINT + "/delegation/delegate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        validator,
        amount
      })
    })
      .then(res => {
        if (res.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }

        return res.json();
      })
      .then(res => {
        if (res.ok === false) {
          return false;
        }

        return dispatch(delegateSuccess(res));
      })
      .catch(error => {
        if (error.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }
      });
  };
}
