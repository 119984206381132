export const NEW_RATE_UPDATE_SUCCESS = 'NEW_RATE_UPDATE_SUCCESS';


export const START_POLLING_NEW_RATE ='START_POLLING_NEW_RATE'
export const STOP_POLLING_NEW_RATE ='STOP_POLLING_NEW_RATE'


export function startPollingNewRate() {
    return {
        type: START_POLLING_NEW_RATE
    }
}

export function newRateUpdateSuccess(data) {

    return {
        type: NEW_RATE_UPDATE_SUCCESS,
        data: data
    }
}