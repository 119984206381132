import React, { PureComponent } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import { COLD_WALLET_ACCOUNT_TYPE } from "../../constants/common";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const appendActive = (className, location, path) => {
  return location.pathname === path ? className + " active-nav" : className;
};

class SideBar extends PureComponent {
  state = { mobileToggled: false };

  constructor(props) {
    super(props);

    this.toggleMobile = this.toggleMobile.bind(this);
    this.hideMobile = this.hideMobile.bind(this);
  }

  toggleMobile() {
    this.setState({ mobileToggled: !this.state.mobileToggled });
  }

  hideMobile() {
    this.setState({ mobileToggled: false });
  }

  render() {
    const { user, translate } = this.props;
    const isColdWallet = user.account_type === COLD_WALLET_ACCOUNT_TYPE;
    const location = this.props.location;

    return (
      <>
        <div id="nav-w">
          <div id="nav">
            <a href="/" className="logo"></a>
            <Link
              to="/"
              className={appendActive("nav-1", location, "/")}
              title={translate("common.home")}
              onClick={this.hideMobile}
            ></Link>
            <Link
              to={"/transactions"}
              className={appendActive("nav-2", location, "/transactions")}
              title={translate("common.transactions")}
            ></Link>
            <Link
              to={"/posmining"}
              className={appendActive("nav-3", location, "/posmining")}
              title={translate("common.posmining")}
            ></Link>
            <Link
              to={"/validators"}
              className={appendActive("nav-6", location, "/validators")}
              title={translate("common.validators")}
            ></Link>
            {!isColdWallet && (
              <>
                <Link
                  to={"/settings"}
                  className={appendActive("nav-4", location, "/settings")}
                  title={translate("common.settings")}
                ></Link>
              </>
            )}
            <div className="clear"></div>
          </div>
        </div>

        <div className="opennav" onClick={this.toggleMobile}></div>

        <div
          id="mobile-nav-w"
          style={{ display: this.state.mobileToggled ? "block" : "none" }}
        >
          <div id="mobile-nav">
            <Link
              onClick={this.hideMobile}
              to={"/transactions"}
              className="nav-2"
            ></Link>
            <Link
              onClick={this.hideMobile}
              to={"/paramining"}
              className="nav-3"
            ></Link>
            <Link
              onClick={this.hideMobile}
              to={"/settings"}
              className="nav-4"
            ></Link>
            <Link
              onClick={this.hideMobile}
              to={"/validators"}
              className="nav-6"
            ></Link>

            <Link
              onClick={this.hideMobile}
              to={"/logout"}
              className="log-out"
            ></Link>

            <div className="clear"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User
});

const enhance = compose(withRouter, connect(mapStateToProps), withLocalize);

export default enhance(SideBar);
