const bip39 = require("bip39");
const bip32 = require("bip32");

const DERIVE_PATH = "44'/118'/0'/0/0";

// Generate a new mnemonic code
exports.generateMnemonic = function () {
  return bip39.generateMnemonic(256);
};

// Generate all the account info (privkey, pubkey, address) from the mnemonic code
exports.generateAccountFromMnemonic = function (mnemonic) {
  const seed = bip39.mnemonicToSeedSync(mnemonic);
  const master = bip32.fromSeed(seed);
  const wallet = master.derivePath(DERIVE_PATH);

  return {
    pubKey: wallet.publicKey,
    privKey: wallet.privateKey,
    address: exports.getAddressFromPublicKey(wallet.publicKey),
    chainCode: wallet.chainCode,
  };
};
