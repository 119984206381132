import { createAction } from "../utils/factories";

export const TX_SEND_PENDING = "TX_SEND_PENDING";
export const TX_SEND_SUCCESS = "TX_SEND_SUCCESS";
export const TX_SEND_ERROR = "TX_SEND_ERROR";

export const TX_SEND_MNEMO_PENDING = "TX_SEND_MNEMO_PENDING";
export const TX_SEND_MNEMO_ERROR = "TX_SEND_MNEMO_PENDING";
export const TX_SEND_MNEMO_SUCCESS = "TX_SEND_MNEMO_SUCCESS";

export const TX_FETCH_PENDING = "TX_FETCH_PENDING";
export const TX_FETCH_SUCCESS = "TX_FETCH_SUCCESS";
export const TX_FETCH_ERROR = "TX_FETCH_ERROR";

export const TRANSACTIONS_FETCH_PENDING = "TRANSACTIONS_FETCH_PENDING";
export const TRANSACTIONS_FETCH_SUCCESS = "TRANSACTIONS_FETCH_SUCCESS";
export const TRANSACTIONS_FETCH_ERROR = "TRANSACTIONS_FETCH_ERROR";

export const FETCH_TXS = "FETCH_TXS";

export const START_TXS_POLLING = "START_TXS_POLLING";
export const STOP_TXS_POLLING = "STOP_TXS_POLLING";

export const SET_SELECT_COIN = "SET_SELECT_COIN";

export function txSendPending() {
  return {
    type: TX_SEND_PENDING,
  };
}

export function txSendSuccess(data) {
  return {
    type: TX_SEND_SUCCESS,
    data: data,
  };
}

export function txSendError(error) {
  return {
    type: TX_SEND_ERROR,
    error: error,
  };
}

export function txFetchPending() {
  return {
    type: TX_FETCH_PENDING,
  };
}

export function txFetchSuccess(data) {
  return {
    type: TX_FETCH_SUCCESS,
    data: data,
  };
}

export function txFetchError(error) {
  return {
    type: TX_FETCH_ERROR,
    error: error,
  };
}

export function startTxsPolling(address) {
  return {
    type: START_TXS_POLLING,
    payload: address,
  };
}

export function setSelectedCoin(coin) {
  return {
    type: SET_SELECT_COIN,
    payload: coin,
  };
}

export const sendMnemoTxPending = () => createAction(TX_SEND_MNEMO_PENDING);
export const sendMnemoTxSuccess = (data) =>
  createAction(TX_SEND_MNEMO_SUCCESS, data);
