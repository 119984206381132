import React, { Component } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Translate, withLocalize } from "react-localize-redux";
import { COLD_WALLET_ACCOUNT_TYPE } from "../../constants/common";
import bech32Validate from "../../utils/address";
import sendTx from "../../api/sendTx";
import sendTxMnemo from "../../api/sendTxMnemo";
import "./styles/TransferTabs.scss";
import SelectCoin from "../ SelectCoin";

// const FEE = 0.25;

const calculateWithFee = amount => {
  // const amnt = parseFloat(amount);

  // if (amnt < 4) {
  return parseFloat(amount) + 0.001;
  // }

  // return parseFloat(amount) + (parseFloat(amount) * FEE) / 100;
};

class TransferTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      to: "",
      amount: "",
      sent: false,
      copied: false,
      tx: "",
      address_error: false,
      amount_error: false,
      isValidPinCode: true,
      comission: 0.001,
      pin_code: "",
      selectedCoin: this.props.selectedCoin
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      user: { profile_error }
    } = this.props;

    if (profile_error && profile_error !== prevProps.user.profile_error) {
      this.setState({
        sent: false,
        isValidPinCode: !profile_error.include("pin")
      });
    }

    if (this.props.selectedCoin !== prevProps.selectedCoin) {
      this.setState({ selectedCoin: this.props.selectedCoin });
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const {
      account: { balance },
      user: { pin_set, account_type },
      newBalances
    } = this.props;
    const coin = this.state.selectedCoin.toLocaleLowerCase();
    const newBalancesObj = newBalances.reduce((acc, item) => {
      acc[item.denom] = item.amount || 0;
      return acc;
    }, {});

    const { to, amount: stateAmount, pin_code } = this.state;

    if (to && stateAmount && newBalancesObj[coin]) {
      const currentBalance = parseInt(newBalancesObj[coin], 10) / 1000000;

      const addressErr = !bech32Validate(to);
      const amount = parseFloat(stateAmount);
      const amountErr = calculateWithFee(amount) > currentBalance;
      const pinCodErr =
        pin_code && (!pin_code.match("[0-9]") || pin_code.length < 4);

      this.setState({
        address_error: addressErr,
        amount_error: amountErr,
        isValidPinCode: !pinCodErr,
        pin_code: ""
      });

      if (!addressErr && !amountErr && (!pinCodErr || !pin_set)) {
        this.props.dispatch(
          account_type === COLD_WALLET_ACCOUNT_TYPE
            ? sendTxMnemo(
                this.props.user,
                to,
                amount.toString(),
                this.state.selectedCoin.toLocaleLowerCase()
              )
            : sendTx(
                "transfer",
                to,
                pin_code,
                amount,
                this.state.selectedCoin.toLowerCase()
              )
        );

        this.setState({ sent: true });
      }
    }
  };

  reset = () => {
    this.setState({
      to: "",
      amount: "",
      sent: false,
      tx: "",
      address_error: false,
      amount_error: false,
      isValidPinCode: true,
      comission: 0.001,
      pin_code: ""
    });
  };

  onTabClick = tab => e => {
    e.preventDefault();

    this.setState({ activeTab: tab });
  };

  render() {
    const { user, tx, newBalances } = this.props;
    const {
      activeTab,
      amount_error,
      address_error,
      isValidPinCode,
      to,
      amount,
      pin_code
    } = this.state;

    let errorMessage = null;

    if (!tx.pending) {
      errorMessage = "";
      if (tx.error && typeof tx.error === "string") {
        if (tx.error === "error.wrong_pin") {
          errorMessage = "error.wrong_pin";
        } else if (tx.error === "error.no_pin") {
          errorMessage = "error.no_pin";
        } else {
          errorMessage = "transaction.send_error";
        }
      }
    }

    return (
      <>
        <a
          onClick={this.onTabClick(0)}
          href=""
          className={activeTab === 0 ? "active" : null}
        >
          <Translate id="wallet.send" />
        </a>
        <a
          onClick={this.onTabClick(1)}
          href=""
          className={activeTab === 1 ? "active" : null}
        >
          <Translate id="wallet.receive" />
        </a>
        <div className="clear" />

        {activeTab === 0 && this.state.sent && !tx.pending && !tx.error && (
          <div className="formbox receive-tab">
            <h5 className="receive-tab__title">
              <Translate id="transaction.yay" />
            </h5>
            <p className="receive-tab__address">
              <Translate id="transaction.sent_message" />
            </p>
            <div className="submit">
              <button type="submit" name="submit" onClick={this.reset}>
                <Translate id="transaction.send_another" />
              </button>
            </div>
          </div>
        )}
        {activeTab === 0 && this.state.sent && !tx.pending && tx.error && (
          <div className="formbox receive-tab">
            <h5 className="receive-tab__title">
              <Translate id="transaction.whoops" />
            </h5>
            <p className="receive-tab__address">
              <Translate id={errorMessage} />
            </p>
            <div className="submit">
              <button type="submit" name="submit" onClick={this.reset}>
                <Translate id="transaction.try_again" />
              </button>
            </div>
          </div>
        )}

        {activeTab === 0 && (!this.state.sent || tx.pending) && (
          <div className="formbox">
            <form name="form" id="form-transfer">
              <Translate>
                {({ translate }) => (
                  <input
                    value={to}
                    onChange={e => this.setState({ to: e.target.value })}
                    type="text"
                    size="50"
                    name="address"
                    autocomplete="off"
                    className={address_error ? "error" : ""}
                    placeholder={translate("transaction.enter_address")}
                  />
                )}
              </Translate>
              <Translate>
                {({ translate }) => (
                  <input
                    value={amount}
                    onChange={e => this.setState({ amount: e.target.value })}
                    type="number"
                    size="50"
                    min={0}
                    step={0.001}
                    name="amount"
                    autocomplete="off"
                    className={amount_error ? "error" : ""}
                    placeholder={translate("transaction.enter_amount")}
                  />
                )}
              </Translate>

              {user.pin_set && (
                <div style={{ float: "left", width: "100%" }}>
                  <input
                    autocomplete="off"
                    value={this.state.pin_code}
                    onChange={e => this.setState({ pin_code: e.target.value })}
                    type="password"
                    size="50"
                    name="pin"
                    className={!isValidPinCode ? "error" : ""}
                    placeholder="PIN"
                  />
                </div>
              )}

              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div style={{ height: "55.96px" }}>
                  <div style={{ marginBottom: "5px" }}>
                    {" "}
                    <Translate id="transaction.total_amount" />:{" "}
                  </div>
                  <span>{amount ? calculateWithFee(amount) : 0}</span>
                </div>
                <div>
                  <div>
                    <Translate id="transaction.currency" />
                  </div>
                  <SelectCoin
                    balances={newBalances}
                    account_type={user.account_type}
                  />
                </div>
              </p>
              <div className="submit">
                <button
                  type="button"
                  onClick={this.onSubmit}
                  disabled={tx.pending}
                >
                  <Translate id="transaction.send" />
                </button>
              </div>
              <div className="clear" />
            </form>
          </div>
        )}

        {activeTab === 1 && (
          <div className="formbox receive-tab">
            <h5 className="receive-tab__title">
              <Translate id="wallet.your_address" />:
            </h5>
            <p className="receive-tab__address">{user.address}</p>
            <div className="submit">
              <CopyToClipboard
                text={this.props.user.address}
                onCopy={() => this.setState({ copied: true })}
              >
                {this.state.copied ? (
                  <button type="submit" name="submit">
                    <i className="fa fa-check" />{" "}
                    <Translate id="wallet.copied" />
                  </button>
                ) : (
                  <button type="submit" name="submit">
                    <Translate id="wallet.copy" />
                  </button>
                )}
              </CopyToClipboard>
            </div>
          </div>
        )}

        <div className="clear" />
      </>
    );
  }
}
const mapStateToProps = state => ({
  selectedCoin: state.Tx.selectedCoin,
  newBalances: state.NewUser.newBalance
});

export default connect(mapStateToProps)(withLocalize(TransferTabs));
