import React, { Component } from "react";
import { connect } from "react-redux";
import convertCoins from "../utils/coins";
import loadProfile from "../api/fetchProfile";
import { fetchTxsActionNew } from "../api/fetchTxs";
import { withLocalize, Translate } from "react-localize-redux";
import CountUp from "../components/CountUp";
import MainPageTransactionList from "../components/Transaction/MainPageTransactionList";
import TransferTabs from "../components/TransferTabs/TransferTabs";
import Header from "../components/Header/Header";
import { fetchBalanceNew } from "../actions/newUser";
import fetchValidators from "../api/fetchValidators";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      balanceOURO: 0,
      balanceOldOURO: 0, // old legacy ouro
      balanceSput: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchBalanceNew(this.props.user.address));
    this.props.dispatch(fetchValidators(this.props.user.address));
    this.intervalBalance = setInterval(() => {
      this.props.dispatch(fetchBalanceNew(this.props.user.address));
    }, [20000]);

    // this.props.dispatch (fetchBalanceNew(this.props.user.address))

    const { newBalance } = this.props.balances;
    const balanceOURO = newBalance?.find(
      (b) => b.amount > 0 && b.denom === "ouro"
    );
    const balanceOldOURO = newBalance?.find(
      (b) => b.amount > 0 && b.denom === "old_ouro"
    );
    const balanceSput = newBalance?.find(
      (b) => b.amount > 0 && b.denom === "sput"
    );
    this.setState({
      balanceOURO: balanceOURO?.amount,
      balanceOldOURO: balanceOldOURO?.amount,
      balanceSput: balanceSput?.amount,
    });

    this.props.dispatch(loadProfile(this.props.user.address));
    this.props.dispatch(
      fetchTxsActionNew(
        "all",
        this.props.user.address,
        this.props.tx.Txs.results
      )
    );
  }

  componentWillUnmount() {
    console.log("Greeting unmounted...");
    clearInterval(this.intervalBalance);
  }

  componentDidUpdate(prevProps, prevState) {
    const { newBalance } = this.props.balances;

    const balanceOURO = newBalance?.find(
      (b) => b.amount > 0 && b.denom === "ouro"
    );
    const balanceOldOURO = newBalance?.find(
      (b) => b.amount > 0 && b.denom === "old_ouro"
    );
    const balanceSput = newBalance?.find(
      (b) => b.amount > 0 && b.denom === "sput"
    );

    if (
      this.state.balanceOURO !== balanceOURO?.amount ||
      this.state.balanceOldOURO !== balanceOldOURO?.amount ||
      this.state.balanceSput !== balanceSput?.amount
    ) {
      this.setState({
        balanceOURO: balanceOURO?.amount,
        balanceOldOURO: balanceOldOURO?.amount,
        balanceSput: balanceSput?.amount,
      });
    }
  }

  render() {
    const { tx, account, user } = this.props;
    console.log("render dahsboard", this.props);

    const posmining = account.posmining;
    const perTime = account.posmining.coins_per_time;

    const start = parseInt(posmining.posmined) / 1000000;
    const end =
      (parseInt(posmining.posmined) + parseInt(perTime.day)) / 1000000;

    console.log("start", start, "end", end);

    console.log("LOOK THERE", posmining, perTime, start, end);

    const balances = [
      [this.state.balanceOURO, "OURO"],
      [this.state.balanceSput, "SPUT"],
    ];

    // const graphMarginTop = balances.length * 46;

    return (
      <div id="box-1-w">
        <div id="box-1">
          <div className="box-1-l">
            <div id="top-w">
              <Header address={user.address} />
            </div>

            <div className="balance">
              {balances.map((balance, index) => {
                return (
                  <h2 key={index}>
                    <Translate id="wallet.balance" />
                    <br />
                    <span className="sp1-1">
                      {convertCoins(balance[0])}{" "}
                      <span className="sp1-2">{balance[1]}</span>
                    </span>
                  </h2>
                );
              })}

              {/* <div className="graph">
								<BalanceGraph history={user.history} theme={this.props.theme} />
							</div> */}

              <div className="clear" />
            </div>

            {/* <div className="paramining" />
            <h2>
              <Translate id="delegation.delegated" />
              <br />
              <span className="sp1-1">
                {this.props.validators.total_delegated}
                <span className="sp1-2"> OURO</span>
              </span>
            </h2> */}

            <div className="paramining">
              <h2>
                {/* <Translate id="wallet.paramining" /> */}
                <Translate id="delegation.posDelegationIncome" />
                <br />
                <span
                  className="sp1-1"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "100%", display: "inline-block" }}>
                    <CountUp
                      start={start}
                      end={end}
                      duration={86400000}
                      interval={1000/60}
                    />
                  </div>
                  <span className="sp1-2">OURO</span>
                </span>
              </h2>
              {/* <div
								className="graph"
								style={{ marginTop: graphMarginTop + "px" }}
							>
								<ParaminigGraph history={user.history} />
							</div> */}

              <div className="clear" />
            </div>

            <div className="clear" />
          </div>

          <div className="box-1-r">
            <MainPageTransactionList
              txs={tx.Txs}
              pending={tx.pending}
              rate={this.props.newRate}
              error={tx.error}
              account={account}
            />

            <div className="clear" />
          </div>

          <div className="box-1-b">
            <TransferTabs account={account} tx={tx} user={user} />
          </div>

          <div className="clear" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.Profile,
  user: state.User,
  tx: state.Tx,
  rate: state.Indicators.rate,
  theme: state.Local.theme,
  balances: state.NewUser,
  newRate: state.NewRate.rates,
  validators: state.Validators,
});

export default connect(mapStateToProps)(withLocalize(Dashboard));
