import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from "reactstrap";

import { connect } from "react-redux";
import convertCoins from "../utils/coins";
import DelegateModal from "../components/Modals/DelegateModal";
import UndelegateModal from "../components/Modals/UndelegateModal";
import { Translate } from "react-localize-redux";
import fetchValidators from "../api/fetchValidators";
import fetchRewards from "../api/fetchRewards";
import Header from "../components/Header/Header";
import "./styles/Validator.scss";

const labelStyle = { fontWeight: 700 };

class Validator extends Component {
  constructor(params) {
    super(params);

    this.hideDelegateModal = this.hideDelegateModal.bind(this);
    this.hideUndelegateModal = this.hideUndelegateModal.bind(this);

    this.state = {
      address: "",
      picture: "/assets/images/logo/2.png",
      username: "",
      delegateModal: false,
      undelegateModal: false,
      amount: 0
    };
  }

  hideDelegateModal() {
    this.setState({
      delegateModal: false
    });
  }

  hideUndelegateModal() {
    this.setState({
      undelegateModal: false
    });
  }

  updateValidators = () => {
    this.props.dispatch(fetchValidators(this.props.userData.address));
    this.props.dispatch(fetchRewards(this.props.userData.address));
  };

  componentDidMount() {
    this.updateValidators();

    this.timer = setInterval(this.updateValidators, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { validatorAddress } = this.props.match.params;
    const theme = this.props.local.theme;

    let validatorData = {};

    const validatorLoading = false;

    this.props.validators.validators.map((value, key) => {
      if (value.operator_address === validatorAddress) {
        validatorData = value;

        return false;
      }

      return true;
    });

    if (!validatorData.description) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

    if (
      validatorData &&
      validatorData.description.identity &&
      this.state.address !== validatorAddress
    ) {
      fetch(
        "https://keybase.io/_/api/1.0/user/lookup.json?key_suffix=" +
          validatorData.description.identity +
          "&fields=pictures,basics"
      )
        .then(resp => {
          return resp.json();
        })
        .then(data => {
          const username = data.them[0].basics.username;
          const picture = data.them[0].pictures.primary.url;

          if (picture) {
            this.setState({
              picture,
              username
            });
          }
        });
    }

    if (this.state.address !== validatorAddress) {
      this.setState({
        address: validatorAddress
      });
    }

    let reward = 0;

    if (
      this.props.validators.rewards[validatorData.operator_address] !==
      undefined
    ) {
      reward = this.props.validators.rewards[validatorData.operator_address];
    }

    let availableBalance = convertCoins(this.props.account.balance);

    if (availableBalance > 0.5) {
      availableBalance -= 0.5;
    } else {
      availableBalance = 0;
    }

    return (
      <>
        <div id="box-3-w" style={{ minHeight: "770px" }}>
          <div id="box-3">
            <div id="top-w">
              <Header address={this.props.userData.address} />
            </div>

            <div className="box-3 validator-header">
              <h2>
                <Translate id="delegation.validators" />
              </h2>
            </div>

            <div className="container-fluid validator">
              <DelegateModal
                theme={theme}
                onHide={this.hideDelegateModal}
                address={this.state.address}
                available={availableBalance}
                showModal={this.state.delegateModal}
              />
              <UndelegateModal
                theme={theme}
                onHide={this.hideUndelegateModal}
                address={this.state.address}
                available={convertCoins(validatorData.delegated.amount)}
                showModal={this.state.undelegateModal}
              />

              <div className="row">
                <div className="validator-logo">
                  <div className="text-center card card-body">
                    <div
                      className="validator-avatar"
                      style={{ marginBottom: 15 }}
                    >
                      <img
                        style={{ height: "180px" }}
                        src={this.state.picture}
                        alt={validatorData.description.moniker}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="moniker text-primary">
                      {validatorData.description.moniker}
                    </div>

                    {validatorData.description.identity ? (
                      <div className="identity">
                        <a
                          href={"https://keybase.io/" + this.state.username}
                          target="_blank"
                        >
                          {validatorData.description.identity}{" "}
                          <i
                            className="fas fa-check-circle text-success"
                            title={validatorData.description.identity}
                          ></i>
                        </a>
                      </div>
                    ) : null}

                    <div className="details">
                      <div>{validatorData.description.details}</div>
                    </div>
                    <div className="website">
                      <Link to={validatorData.description.website}>
                        {validatorData.description.website}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="validator-container">
                  <div className="card">
                    <div className="card-header">
                      <span>
                        <Translate id="delegation.information" />
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="validator-info">
                        <div className="validator-balance">
                          <div
                            className="col-12"
                            style={{ marginBottom: "1.2em" }}
                          >
                            <span className="badge badge-primary validator-info__tokens">
                              {convertCoins(validatorData.tokens)} OURO
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="validator-info__item">
                            <div className="label col-sm-4" style={labelStyle}>
                              <span>
                                <Translate id="delegation.identifier" />
                              </span>
                            </div>

                            <div
                              data-operator-address={
                                validatorData.description.moniker
                              }
                              className="value address col-sm-8"
                            >
                              {validatorData.description.moniker}
                            </div>
                          </div>

                          <div className="validator-info__item">
                            <div className="label col-sm-4" style={labelStyle}>
                              <span>
                                <Translate id="delegation.address" />
                              </span>
                            </div>

                            <div
                              data-operator-address={
                                validatorData.operator_address
                              }
                              className="value address col-sm-8"
                            >
                              {validatorData.operator_address}
                            </div>
                          </div>

                          <div className="validator-info__item">
                            <div className="label col-sm-4" style={labelStyle}>
                              <span>
                                <Translate id="delegation.fee" />
                              </span>
                            </div>
                            <div className="value col-sm-8">
                              {Math.round(
                                Number(
                                  validatorData.commission.commission_rates.rate
                                ) * 100
                              )}
                              %
                            </div>
                          </div>

                          <div className="validator-info__item">
                            <div className="label col-sm-4" style={labelStyle}>
                              <span>
                                <Translate id="delegation.details" />
                              </span>
                            </div>
                            <div className="value col-sm-8">
                              <a
                                style={{ color: "#007bff" }}
                                target="_blank"
                                href={
                                  "https://explorer.ouroboros-crypto.com/validator/" +
                                  validatorData.operator_address
                                }
                              >
                                Explorer
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <span>
                        <Translate id="delegation.delegation" />
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="delegation">
                        <div className="delegation-info">
                          <div className="delegation-info__item">
                            <div className="label col-sm-4" style={labelStyle}>
                              <span>
                                <Translate id="delegation.delegated" />
                              </span>
                            </div>
                            <div
                              data-delegated={convertCoins(
                                validatorData.delegated.amount
                              )}
                              className="value address col-sm-8"
                            >
                              {convertCoins(validatorData.delegated.amount)}{" "}
                              OURO
                            </div>
                          </div>

                          <div className="delegation-info__item">
                            <div className="label col-sm-4" style={labelStyle}>
                              <span>
                                <Translate id="delegation.available_reward" />
                              </span>
                            </div>

                            <div
                              data-delegated={reward}
                              className="value address col-sm-8"
                            >
                              {reward} OURO
                            </div>
                          </div>
                        </div>
                        <div className="delegation-btns">
                          <div className="delegators-btns-controls mt-4">
                            <Button
                              size="lg"
                              color="primary"
                              onClick={e =>
                                this.setState({ delegateModal: true })
                              }
                            >
                              <Translate id="delegation.delegate" />
                            </Button>
                          </div>
                          <div className="delegators-btns-controls mt-4">
                            <Button
                              size="lg"
                              color="warning"
                              onClick={e =>
                                this.setState({ undelegateModal: true })
                              }
                            >
                              <Translate id="delegation.undelegate" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  account: state.Profile,
  userData: state.User,
  tx: state.Tx,
  validators: state.Validators,
  rate: state.Indicators.rate,
  local: state.Local
});

export default withRouter(connect(mapStateToProps)(Validator));
