import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import loadProfile from "../api/fetchProfile";
import updateAccountAction from "../api/updateAccount";
import sendReinvest from "../api/sendReinvest";
import { resetReinvest } from "../actions/reinvest";
import { Translate, withLocalize } from "react-localize-redux";
import ReinvestResult from "../components/Modals/ReinvestResult";
import convertCoins from "../utils/coins";
import dailyPercent from "../utils/paramining";
import { SAVINGS } from "../settings";
import { COLD_WALLET_ACCOUNT_TYPE } from "../constants/common";
import Header from "../components/Header/Header";
import Preloader from "../components/Preloader";
import "./styles/Paramining.scss";

class Paramining extends Component {
  state = {
    reinvest: 0,
    showReinvestSavedLabel: false
  };

  componentDidMount() {
    this.setState({ reinvest: this.props.user.reinvest });

    this.updateReinvest = this.updateReinvest.bind(this);

    this.props.dispatch(loadProfile(this.props.user.address));
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (
      prevProps.user.update_pending !== user.update_pending &&
      !user.update_pending &&
      prevProps.user.update_pending
    ) {
      this.setState({
        showReinvestSavedLabel: true
      });
      this.hideReinvestSavedLabel();
    }
  }

  saveReinvest = e => {
    e.preventDefault();

    this.props.dispatch(updateAccountAction({ reinvest: this.state.reinvest }));
  };

  updateReinvest(newValue) {
    newValue = newValue.replace(/,/g, ".");

    if (
      newValue.startsWith("0") &&
      !newValue.includes(",") &&
      !newValue.includes(".") &&
      newValue.length > 1
    ) {
      newValue = newValue.substr(1);
    }

    if (newValue === "") {
      newValue = "0";
    }

    let value = parseFloat(newValue);

    if (value !== 0 && value < 0.02) {
      value = 0.02;
      newValue = "0.02";
    }

    if (value === 0 || value >= 0.02) {
      this.setState({ reinvest: newValue });
    }
  }

  onReinvestBtnClick = () => {
    const { user, dispatch } = this.props;

    dispatch(sendReinvest(user));
  };

  onResetReinvest = () => {
    const { dispatch } = this.props;

    dispatch(resetReinvest());
  };

  hideReinvestSavedLabel = () => {
    setTimeout(() => {
      this.setState({ showReinvestSavedLabel: false });
    }, 3000);
  };

  render() {
    const { user, account, reinvest } = this.props;
    const { showReinvestSavedLabel } = this.state;

    const { posmining } = account;
    const perTime = posmining.coins_per_time;

    const isColdWelly = user.account_type === COLD_WALLET_ACCOUNT_TYPE;

    const lastTransaction = new Date(posmining.posmining.last_transaction);
    const today = new Date(new Date().toUTCString());
    const daysSinceTransaction = Math.floor(
      (today.getTime() - lastTransaction.getTime()) / (1000 * 3600 * 24)
    );
    const daysRounded = Math.floor(daysSinceTransaction / 30);

    const daily = parseInt(posmining.posmining.daily_percent, 10) / 100;
    const structure = parseInt(posmining.posmining.structure_coff, 10) / 100;
    const savings = daysRounded < 12 ? SAVINGS[daysRounded] : SAVINGS[12];
    const correctionCoff = 100 - parseInt(posmining.correction_coff);

    const minimalReinvest = 0.02;
    const currentParamining = parseInt(posmining.posmined) / 1000000;

    const btnClass =
      currentParamining < minimalReinvest ? "btn-dark" : " btn-blue-grey";

    return (
      <div id="box-3-w">
        <div id="box-3">
          <div id="top-w">
            <Header address={user.address} />
          </div>

          <div className="box-3">
            {/* <h2>
              <Translate id="wallet.paramining" />
            </h2> */}
            <h2>
              <Translate id="paramining.delegated_income" />
            </h2>
          </div>

          <div
            className="box-3-l"
            style={{ width: "100%", boxSizing: "border-box" }}
          >
            {/* <h3>
              <Translate id="paramining.reinvest" />
            </h3> */}
            <h3>
              <Translate id="paramining.reinvest_delegated_income" />
            </h3>
            <p className="p3-1">
              <Translate id="paramining.reinvest_explanation" />
            </p>
            <p className="p3-2">
              <Translate id="paramining.reinvest_fee" /> - 0.01 OURO.
            </p>
            {isColdWelly ? (
              <div className="submit">
                <button
                  className={
                    "btn btn-primary w-md waves-effect waves-light " + btnClass
                  }
                  type="button"
                  onClick={this.onReinvestBtnClick}
                  disabled={
                    currentParamining < minimalReinvest || reinvest.pending
                  }
                >
                  <Translate id="paramining.get_reinvest" />
                </button>
                {reinvest.isFinished ? (
                  <p className={"text-success"}>
                    <Translate id="paramining.reinvest_request_body_success" />
                  </p>
                ) : null}
              </div>
            ) : (
              <>
                <div className="formbox">
                  <form id="form-reinvest" onSubmit={this.saveReinvest}>
                    <input
                      id="example-text-input"
                      type="text"
                      name="amount"
                      value={this.state.reinvest}
                      onChange={e => {
                        this.updateReinvest(e.target.value);
                      }}
                      placeholder={Translate("paramining.reinvest_threshold")}
                    />

                    <div className="submit">
                      <button type="submit" disabled={user.update_pending}>
                        <Translate id={"settings.save"} />
                        {user.update_pending && <Preloader />}
                      </button>
                      <div
                        className={`submit-label ${
                          showReinvestSavedLabel ? "submit-label_visible" : ""
                        }`}
                      >
                        <Translate id={"paramining.reinvest_saved"} />
                      </div>
                    </div>

                    <div className="clear"></div>
                  </form>
                </div>

                <div className="clear"></div>
              </>
            )}
            <div className="clear"></div>
          </div>

          {/* <div className="box-3-r">
						<h3>
							<Translate id="paramining.options" />
						</h3>

						<p className="p3-3 p3-3-1">
							<Translate id="paramining.monthly_percent" />: <span>3%</span>
						</p>

						<p className="p3-3 p3-3-2">
							<Translate id="paramining.stacked_monthly_percent" />: <span>5%</span>
						</p>
						<p className="p3-3 p3-3-6">
							<Translate id="paramining.daily_profit" />:<br />
							<span>{convertCoins(perTime.day)} OURO</span>
						</p>

						<p className="p3-3 p3-3-6">
							<Translate id="paramining.monthly_profit" />:<br />
							<span>{convertCoins(perTime.day * 30)} OURO</span>
						</p>

						<div className="clear"></div>
					</div> */}

          <div className="clear"></div>

          <div className="box-3-b">
            <p className="p3-4 p3-4-1">
              <Translate id="paramining.every_second" />
              <br />
              <span className="sp3-1">
                {convertCoins(perTime.second)}
                <span className="sp3-2"> OURO</span>
              </span>
              <br />
              <span className="sp3-3">{convertCoins(perTime.second)}</span>
            </p>

            <p className="p3-4 p3-4-2">
              <Translate id="paramining.every_minute" />
              <br />
              <span className="sp3-1">
                {convertCoins(perTime.minute)}
                <span className="sp3-2"> OURO</span>
              </span>
              <br />
              <span className="sp3-3">{convertCoins(perTime.minute)}</span>
            </p>

            <p className="p3-4 p3-4-3">
              <Translate id="paramining.every_hour" />
              <br />
              <span className="sp3-1">
                {convertCoins(perTime.hour)}
                <span className="sp3-2"> OURO</span>
              </span>
              <br />
              <span className="sp3-3">{convertCoins(perTime.hour)}</span>
            </p>

            <p className="p3-4 p3-4-4">
              <Translate id="paramining.every_day" />
              <br />
              <span className="sp3-1">
                {convertCoins(perTime.day)}
                <span className="sp3-2"> OURO</span>
              </span>
              <br />
              <span className="sp3-3">{convertCoins(perTime.day)}</span>
            </p>

            <div className="clear"></div>
          </div>

          <div className="clear"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.Profile,
  user: state.User,
  reinvest: state.Reinvest
});

export default connect(mapStateToProps)(withLocalize(Paramining));
