import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { SigningStargateClient } from "@cosmjs/stargate";
import { MsgWithdrawDelegatorReward } from "cosmjs-types/cosmos/distribution/v1beta1/tx";

import { API_ENDPOINT, RPC_ENDPOINT } from "../settings";
import { logout, stopPollingProfile } from "../actions/user";
import { resetReward } from "../actions/validators";

export function sendRewardMnemoAction(address, mnemonic, validators) {
  return dispatch => {
    if (!mnemonic) {
      return;
    }
    const realValidators = Object.keys(validators.rewards);
    if (realValidators.length === 0) {
      return;
    }

    DirectSecp256k1HdWallet.fromMnemonic(mnemonic, {
      prefix: "ouro"
    }).then(wallet => {
      wallet.getAccounts().then(accounts => {
        SigningStargateClient.connectWithSigner(RPC_ENDPOINT, wallet).then(
          client => {
            const fee = {
              amount: [
                {
                  denom: "ouro",
                  amount: "2000"
                }
              ],
              gas: "95000000000"
            };
            debugger;
            const withdrawMsgs = realValidators.map(validator => ({
              typeUrl:
                "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward",
              value: MsgWithdrawDelegatorReward.fromPartial({
                delegatorAddress: address,
                validatorAddress: validator
              })
            }));
            client.signAndBroadcast(address, withdrawMsgs, fee).then(result => {
              console.log("MEMO_REWARD", result);
              return dispatch(resetReward());
            });
          }
        );
      });
    });
  };
}

export function sendRewardAction() {
  return dispatch => {
    const token = localStorage.token;

    if (!token) {
      return;
    }

    fetch(API_ENDPOINT + "/delegation/reward", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({})
    })
      .then(res => {
        if (res.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }

        dispatch(resetReward());
        return res.json();
      })
      .then(res => {
        if (res.ok === false) {
          return false;
        }

        return true;
      })
      .catch(error => {
        if (error.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }
      });
  };
}
