import {API_ENDPOINT} from "../settings";
import { editWallet } from "../actions/wallets";

export default function editNewWalletAction(walletAddress, newValue) {
    return dispatch => {
        // Мы в процессе загрузки данных
        const token = localStorage.token;

        if (!token) {
            return;
        }

        fetch(API_ENDPOINT + '/wallets/' + walletAddress, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                'title': newValue,
            })
        })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return dispatch(editWallet(res.data));;
            })
            .catch(error => {
                console.log(error);
            });
    }
}