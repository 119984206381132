export const PROFILE_PENDING = "PROFILE_PENDING";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const ACCOUNT_PENDING = "ACCOUNT_PENDING";
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
export const ACCOUNT_ERROR = "ACCOUNT_ERROR";

export const ACCOUNT_UPDATE_PENDING = "ACCOUNT_UPDATE_PENDING";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_ERROR = "ACCOUNT_UPDATE_ERROR";

export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const AUTH_PENDING = "AUTH_PENDING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

export const TOKEN_UPDATE = "TOKEN_UPDATE";

export const AUTH_MNEMONIC_SUCCESS = "AUTH_MNEMONIC_SUCCESS";

export const LOGOUT = "LOGOUT";

export const FA_REQUIRED = "2FA_REQUIRED"; // Необходимость в двухфакторке
export const FA_ENABLED = "2FA_ENABLE"; // Двухфакторка была включена
export const FA_DISABLED = "2FA_DISABLED"; // Двухфакторка была выключена
export const FA_ERROR = "2FA_ERROR"; // Ошибка, связанная с двухфакторкой
export const FA_SHOWED = "2FA_SHOWED"; // Показали всплывашку с 2FA

export const START_POLLING_PROFILE = "START_POLLING_PROFILE";
export const STOP_POLLING_PROFILE = "STOP_POLLING_PROFILE";

export const CHANGE_REINVEST = "CHANGE_REINVEST";

export const GET_RESET_PASSWORD_LINK_PADDING = "GET_ResetPassword_LINK";
export const GET_RESET_PASSWORD_LINK_SUCCESS = "GET_ResetPassword_LINK";
export const GET_RESET_PASSWORD_LINK_ERROR = "GET_ResetPassword_LINK";

export const RESET_PASSWORD_PADDING = "RESET_PASSWORD_PADDING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const RESET_PASSWORD_TOKEN_PADDING = "RESET_PASSWORD_TOKEN_PADDING";
export const RESET_PASSWORD_TOKEN_SUCCESS = "RESET_PASSWORD_TOKEN_SUCCESS";
export const RESET_PASSWORD_TOKEN_ERROR = "RESET_PASSWORD_TOKEN_ERROR";

export const CHANGE_EMAIL_PENDING = "CHANGE_EMAIL_PENDING";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_ERROR = "CHANGE_EMAIL_ERROR";

export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";

export const PARTICIPATE_SUCCESS = "PARTICIPATE_SUCCESS";

export function profilePending() {
  return {
    type: PROFILE_PENDING,
  };
}

export function profileSuccess(data) {
  return {
    type: PROFILE_SUCCESS,
    data: data,
  };
}

export function profileError(error) {
  return {
    type: PROFILE_ERROR,
    error: error,
  };
}

export function registerPending() {
  return {
    type: REGISTER_PENDING,
  };
}

export function registerSuccess(data) {
  return {
    type: REGISTER_SUCCESS,
    data: data,
  };
}

export function registerError(error) {
  return {
    type: REGISTER_ERROR,
    error: error,
  };
}

export function authPending() {
  return {
    type: AUTH_PENDING,
  };
}

export function authSuccess(data) {
  return {
    type: AUTH_SUCCESS,
    data: data,
  };
}

export function authMnemonicSuccess(data) {
  return {
    type: AUTH_MNEMONIC_SUCCESS,
    data: data,
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    error: error,
  };
}

export function accountPending() {
  return {
    type: ACCOUNT_PENDING,
  };
}

export function accountUpdatePending() {
  return {
    type: ACCOUNT_UPDATE_PENDING,
  };
}

export function accountUpdateSuccess(data) {
  return {
    type: ACCOUNT_UPDATE_SUCCESS,
    data: data,
  };
}

export function accountUpdateError(error) {
  return {
    type: ACCOUNT_UPDATE_ERROR,
    error: error,
  };
}

export function accountSuccess(data) {
  return {
    type: ACCOUNT_SUCCESS,
    data: data,
  };
}

export function accountError(error) {
  return {
    type: ACCOUNT_ERROR,
    error: error,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function faRequired() {
  return {
    type: FA_REQUIRED,
  };
}

export function FAEnabled() {
  return {
    type: FA_ENABLED,
  };
}

export function FADisabled() {
  return {
    type: FA_DISABLED,
  };
}

export function FAError(error) {
  return {
    type: FA_ERROR,
    error: error,
  };
}

export function startPollingProfile(address) {
  return {
    type: START_POLLING_PROFILE,
    payload: address,
  };
}

export function stopPollingProfile() {
  return {
    type: STOP_POLLING_PROFILE,
  };
}

export function getResetPasswordLinkPadding() {
  return {
    type: GET_RESET_PASSWORD_LINK_PADDING,
  };
}

export function getResetPasswordLinkError(error) {
  return {
    type: GET_RESET_PASSWORD_LINK_ERROR,
    error: error,
  };
}

export function getResetPasswordLinkSuccess() {
  return {
    type: GET_RESET_PASSWORD_LINK_SUCCESS,
  };
}

export function resetPasswordPadding() {
  return {
    type: RESET_PASSWORD_PADDING,
  };
}

export function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error: error,
  };
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordTokenPadding() {
  return {
    type: RESET_PASSWORD_TOKEN_PADDING,
  };
}

export function resetPasswordTokenError(error) {
  return {
    type: RESET_PASSWORD_TOKEN_ERROR,
    error: error,
  };
}

export function resetPasswordTokenSuccess() {
  return {
    type: RESET_PASSWORD_TOKEN_SUCCESS,
  };
}

export function setAccountType(type) {
  return {
    type: SET_ACCOUNT_TYPE,
    payload: type,
  };
}

export function updateToken(token) {
  return {
    type: TOKEN_UPDATE,
    data: token,
  };
}

export function changeEmailPending() {
  return {
    type: CHANGE_EMAIL_PENDING,
  };
}

export function changeEmailError(error) {
  return {
    type: CHANGE_EMAIL_ERROR,
    error: error,
  };
}

export function changeEmailSuccess() {
  return {
    type: CHANGE_EMAIL_SUCCESS,
  };
}
