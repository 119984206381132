import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import fetchValidators from "../api/fetchValidators";
import MaterialTable from "material-table";
import Modal from "react-modal";
import tableIcons from "../components/MaterialTable/TableIcons";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import convertCoins from "../utils/coins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { sendRewardAction, sendRewardMnemoAction } from "../api/sendReward";
import fetchRewards from "../api/fetchRewards";
import Header from "../components/Header/Header";
import "./styles/Validators.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement("#root");

class Validators extends Component {
  constructor(props) {
    super(props);

    this.getReward = this.getReward.bind(this);
    this.updateValidators = this.updateValidators.bind(this);

    this.state = {
      successModal: false
    };
  }

  updateValidators = () => {
    this.props.dispatch(fetchValidators(this.props.userData.address));
    this.props.dispatch(fetchRewards(this.props.userData.address));
  };

  componentDidMount() {
    this.updateValidators();

    this.timer = setInterval(this.updateValidators, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getReward() {
    if (this.props.userData.mnemonic) {
      this.props.dispatch(
        sendRewardMnemoAction(
          this.props.userData.address,
          this.props.userData.mnemonic,
          this.props.validators
        )
      );
    } else {
      this.props.dispatch(sendRewardAction());
    }

    this.setState({
      successModal: true
    });
  }

  onClose = () => {
    this.setState({ successModal: false });
  };

  render() {
    if (this.props.validators.validators.length === 0) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div id="box-3-w validators" style={{ minHeight: "770px" }}>
          <div id="box-3">
            <div id="top-w">
              <Header address={this.props.userData.address} />
            </div>

            <div className="box-3 validator-header">
              <h2>
                <Translate id="delegation.validators" />
              </h2>
            </div>
            <div className="card-body">
              <Modal
                isOpen={this.state.successModal}
                style={customStyles}
                onRequestClose={this.onClose}
              >
                <div className="validator-modal">
                  <h4>
                    <Translate id="transaction.yay" />
                  </h4>

                  <p>
                    <Translate id="delegation.reward_charged" />
                  </p>

                  <FontAwesomeIcon
                    className="success-icon"
                    icon={faCheckCircle}
                  />
                </div>
              </Modal>

              <h4 className="validator-header">
                <Translate id="delegation.available_reward" />:{" "}
                {this.props.validators.total_rewards} OURO
              </h4>

              <div className="delegators-btns-controls mt-4 validator-get-reward-btn">
                <Button size="lg" color="primary" onClick={this.getReward}>
                  <Translate id="delegation.get_reward" />
                </Button>
              </div>

              <div className="mt-5">
                {this.props.validators.validators.length === 0 ? (
                  <Spinner />
                ) : (
                  <MaterialTable
                    icons={tableIcons}
                    options={{ paging: false }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: this.props.translate(
                          "delegation.no_records"
                        )
                      },
                      toolbar: {
                        searchPlaceholder: this.props.translate(
                          "delegation.search"
                        ),
                        searchTooltip: this.props.translate("delegation.search")
                      }
                    }}
                    columns={[
                      {
                        title: this.props.translate("delegation.identifier"),
                        field: "description.moniker",
                        render: rowData => (
                          <Link
                            style={{ fontSize: "1.05rem", color: "#007bff" }}
                            to={`/validators/${rowData.operator_address}`}
                          >
                            <Button color="primary">
                              {rowData.description.moniker}
                            </Button>
                          </Link>
                        )
                      },
                      {
                        title: this.props.translate("delegation.active"),
                        field: "jailed",
                        render: rowData =>
                          rowData.jailed ? (
                            <div style={{ color: "red" }}>
                              <Translate id="delegation.jailed" />
                            </div>
                          ) : (
                            <div style={{ color: "green" }}>
                              <Translate id="delegation.actived" />
                            </div>
                          )
                      },
                      {
                        title: this.props.translate("delegation.stake"),
                        field: "tokens",
                        type: "numeric",
                        render: rowData => convertCoins(rowData.tokens)
                      },
                      {
                        title: this.props.translate("delegation.commission"),
                        field: "commission.commission_rates.rate",
                        type: "numeric",
                        render: rowData => {
                          return (
                            parseFloat(
                              rowData.commission.commission_rates.rate
                            ) *
                              100 +
                            "%"
                          );
                        }
                      },
                      {
                        title: this.props.translate("delegation.delegated"),
                        field: "delegated",
                        type: "numeric",
                        render: rowData =>
                          convertCoins(rowData.delegated.amount)
                      }
                    ]}
                    data={this.props.validators.validators}
                    title={this.props.translate("delegation.validators")}
                  />
                )}
              </div>
            </div>

            <div className="clear"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  account: state.Profile,
  userData: state.User,
  tx: state.Tx,
  validators: state.Validators,
  rate: state.Indicators.rate
});

export default connect(mapStateToProps)(withLocalize(Validators));
